import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { ModalVersion } from 'modals';

import { Section, Table, Index, InfosBar } from 'components';
import { Container, Button, Label } from 'semantic-ui-react'

import { BreadcrumbHelper, Typeof, Constants } from 'utils';

import compareVersions from 'compare-versions';

const VERSIONS_PER_PAGE = 10;

class VersionListScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      versions: props.versions,
      versionCriteria: '',
      showVersionCreateModal: false
    };

    this.handleVersionSearch = this.handleVersionSearch.bind(this);

    this.toggleVersionCreateModal      = this.toggleVersionCreateModal.bind(this);
    this.handleVersionCreateModalClose = this.handleVersionCreateModalClose.bind(this);

    this.handleVersionSelect = this.handleVersionSelect.bind(this);
  }

  componentWillMount() {

    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Versions');
    BreadcrumbHelper.flush();
  }

  handleVersionSearch(criteria) {

    this.setState({
      versionCriteria: criteria
    });
  }

  handleVersionSelect(version) {

    //Navigate to version page
    this.props.history.push(`/versions/${version.id}`);
  }

  toggleVersionCreateModal() {
    this.setState({
      showVersionCreateModal: !this.state.showVersionCreateModal
    });
  }

  handleVersionCreateModalClose(version) {

    if (Typeof.object(version)) {

      this.state.versions.push(version);
      this.forceUpdate();
    }

    this.toggleVersionCreateModal();
  }

  render() {

    const { versions } = this.props;

    let columns = [
      {
        id: 'id',
        header: 'Numéro de version',
        value: (version) => version.id,
        sort: (a, b) => {
          return compareVersions(a.id, b.id);
        }
      },
      {
        id: 'options',
        header: 'Option(s)',
        value: (version) => version.options.length,
        fit: true
      },
      {
        id: 'state',
        header: 'Status',
        value: (version) => Constants.getVersionStateInfos(version.state).label,
        render: (version) => {

          let infos = Constants.getVersionStateInfos(version.state);

          return (
            <div>
              <Label empty circular color={infos.color} style={{marginRight: '0.5em'}}/>
              {infos.label}
            </div>
          );
        },
        fit: true,
        sort: (a, b) => {
          return a.state - b.state;
        }
      }
    ];

    return (
      <React.Fragment>

        <Container>

          {/* Versions */}
          <Section title="Liste des versions" onSearch={this.handleVersionSearch} actions={[

            // Add version
            <Button icon="add" content="Ajouter" onClick={this.toggleVersionCreateModal} color="yellow" />
          ]}>

            {versions.length > 0 ? (

              <React.Fragment>

                {/* Number of available versions */}
                <InfosBar mode="column">
                  <span>{versions.length > 1 ? `${versions.length} versions disponibles` : '1 version disponible'}</span>
                </InfosBar>

                {/* Versions list */}
                <Table columns={columns}
                  criteria={this.state.versionCriteria}
                  items={versions}
                  itemsPerPage={VERSIONS_PER_PAGE}
                  onSelect={this.handleVersionSelect}
                  messageEmpty="Aucune version ne correspond à votre recherche"
                  sort="id"
                  direction="descending"
                />

              </React.Fragment>

            ) : (

              // No version available
              <InfosBar mode="row">
                <span>Aucune version disponible</span>
              </InfosBar>

            )}


          </Section>

        </Container>

        {/* Modal create version */}
        {this.state.showVersionCreateModal &&
          <ModalVersion versions={versions} onClose={this.handleVersionCreateModalClose} />
        }

      </React.Fragment>

    );
  }
}

export default withRouter(VersionListScene);
