import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom';

import { Typeof } from 'utils';

import { Dropdown } from 'semantic-ui-react';

class Selection extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, { value }) {

    this.setState({
      value: value
    });

    if(Typeof.func(this.props.onChange)) {
      this.props.onChange(value);
    }
  }

  updateValue() {
    let node = ReactDOM.findDOMNode(this.refs.select);

    if (Typeof.undefined(this.state.value) || Typeof.null(this.state.value)) {
      node.setAttribute('value', "");
    } else {
      node.setAttribute('value', this.state.value);
    }
  }

  componentDidMount() {
    this.updateValue();
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateValue();
  }

  render() {

    let { name, value, onChange, ...rest } = this.props;

    return (
      <React.Fragment>

        <Dropdown
          search={this.props.search}
          searchInput={{ type: 'string' }}
          noResultsMessage={this.props.noResultsMessage}
          ref={'select'}
          name={name}
          className="input-field"
          selection
          onChange={this.handleChange}
          value={this.state.value}
          {...rest}
          clearable={this.props.clearable}
        />

      </React.Fragment>
    );
  }
}

Selection.defaultProps = {
  search: true,
  clearable: false
};

Selection.propTypes = {
  name: PropTypes.string.isRequired,
  search: PropTypes.bool.isRequired,
  clearable: PropTypes.bool.isRequired
};

export default Selection;
