import React from 'react'
import PropTypes from 'prop-types'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { OptionListScene } from 'scenes';

class OptionListContainer extends AbstractContainer {

  getTasks() {

    return [
      {
        key: 'options',
        exec: (done, failure) => {
          API.get('options')
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <OptionListScene options={results.options} />
  }
}

export default OptionListContainer;
