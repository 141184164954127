import React from 'react'
import PropTypes from 'prop-types'

import { Countries, Locales } from 'utils';
import { Flag, Icon } from 'semantic-ui-react';

import './style.css';

class Country extends React.Component {
  render() {

    let country = Countries.getCountry(this.props.country);
    let locale = Locales.getLocale(this.props.locale);

    return (
      <div className="country">
        <Flag name={this.props.country.toLowerCase()} />

        <b>{country.name}</b>

        {/* Locale */}
        {this.props.locale &&
          <div className="locale">
            <Icon name="language" />

            {locale.name}
          </div>
        }

      </div>
    );
  }
}

Country.propTypes = {
  country: PropTypes.string.isRequired,
  locale: PropTypes.string
};

export default Country;
