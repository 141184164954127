import { Typeof } from 'utils';

class BreadcrumbHelper {

  //--Properties
  static _ref = undefined;
  static levels = [];

  /**
   * Initializes the helper
   * @param {Breadcrumb} ref the <Breadcrumb /> ref
   */
  static init(ref) {
    BreadcrumbHelper._ref = ref;
    BreadcrumbHelper.reset();
  }

  /**
   * Resets the breadcrumb
   */
  static reset() {
    BreadcrumbHelper.levels = [];
  }

  /**
   * Pushes a breadcrumb level
   * @param {string} name the name of the level
   * @param {string} path the path of the level
   */
  static push(name, path) {

    let level = {
      index: BreadcrumbHelper.levels.length,
      name: name,
      path: path
    };

    BreadcrumbHelper.levels.push(level);
  }

  /**
   * Force update the breadcrumb ref.
   */
  static flush() {

    //Refresh breadcrumb
    if(Typeof.object(BreadcrumbHelper._ref)) {
      BreadcrumbHelper._ref.forceUpdate();
    }
  }

  /**
   * Gets all breadcrumb levels.
   */
  static getLevels() {
    return BreadcrumbHelper.levels;
  }
}


export default BreadcrumbHelper;
