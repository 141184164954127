import React from 'react';

import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { DummyRoute, PrivateRoute } from 'routes';

import { Splash } from 'components';
import { NotFoundScene, LoginScene, LogoutScene } from 'scenes';
import {
  OptionListContainer,
  VersionListContainer,
  VersionContainer,
  CustomerListContainer,
  CustomerContainer,
  DosicaseListContainer,
  DosicaseContainer,
  MailContainer
} from 'containers';

import { Auth } from 'services';

import 'semantic-ui-css/semantic.min.css'
import 'styles/main.css';

class App extends React.Component {

  constructor() {
    super();

    this.state = {
      isTokenChecked: undefined
    };
  }

  componentWillMount() {
    Auth.checkToken()
    .finally(() => {
      this.setState({ isTokenChecked: true });
    })
  }

  render() {

    return (
      <Router>

        {this.state.isTokenChecked &&
          <Switch>

            {/* Login route */}
            <DummyRoute path="/login" exact component={LoginScene} />

            {/* Logout route */}
            <DummyRoute path="/logout" exact component={LogoutScene} />

            <Redirect from='/' to='/dosicases' exact />

            {/* Options */}
            <PrivateRoute path="/options" exact component={OptionListContainer} roles={Auth.ROLE_API} />

            {/* Versions list */}
            <PrivateRoute path="/versions" exact component={VersionListContainer} roles={Auth.ROLE_API} />

            {/* Versions */}
            <PrivateRoute path="/versions/:version([0-9]+.[0-9]+)" exact component={VersionContainer} roles={Auth.ROLE_API} />
            <PrivateRoute path="/versions/:version([0-9]+.[0-9]+.[0-9]+)" exact component={VersionContainer} roles={Auth.ROLE_API} />

            {/* Customers list */}
            <PrivateRoute path="/customers" exact component={CustomerListContainer} roles={Auth.ROLE_API} />

            {/* Customer details */}
            <PrivateRoute path="/customers/:customer([0-9]+)" exact component={CustomerContainer} roles={Auth.ROLE_API} />

            {/* Dosicase list */}
            <PrivateRoute path="/dosicases" exact component={DosicaseListContainer} roles={Auth.ROLE_API} />

            {/* Dosicase details */}
            <PrivateRoute path="/dosicases/:dosicase([0-9]+)" exact component={DosicaseContainer} roles={Auth.ROLE_API} />

            {/* Mails */}
            <PrivateRoute path="/mails" exact component={MailContainer} roles={Auth.ROLE_API} />

            {/* NotFound route */}
            <DummyRoute component={NotFoundScene} />

          </Switch>
        }

        <Splash message="Vérification du jeton de connexion" visible={!this.state.isTokenChecked} />
      </Router>
    );
  }
}

export default App;
