import { Modal, Button, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import React from 'react'

class NotFoundScene extends React.Component {

  constructor(props) {
    super(props);

    this.onBackHome = this.onBackHome.bind(this);
  }

  onBackHome() {
    this.props.history.push('/');
  }

  render() {
    return (
      <Modal open size='small' dimmer="blurring">
        <Header icon='attention' content='Page non trouvée' color="yellow" style={{ backgroundColor: 'black' }} />
        <Modal.Content>
          <p>La page demandée n'existe pas</p>
        </Modal.Content>
        <Modal.Actions>
          <Button icon="home" content="Retour à l'accueil" onClick={this.onBackHome} color="yellow" />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default withRouter(NotFoundScene);
