import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, Selection } from 'components';

import { Form, Modal, Header, TransitionablePortal, Input as Input2, Label } from 'semantic-ui-react'

import { API } from 'services';

import { Typeof, Constants } from 'utils';

import './style.css';

class ModalEditDosicaseConfiguration extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      }
    };
  }

  handleSubmit(data, onSucess, onError) {

    API.put(`/dosicases/${this.props.dosicase.id}/equipments`, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    let equipments = this.props.dosicase.equipments;

    let formats = Constants.EQUIPMENT_FORMATS.map((format) => {
      return {
        key: format,
        value: format,
        text: Constants.getEquipmentFormat(format)
      }
    })

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size="small">
          <Header icon="edit" content="Modification valise – Équipements" color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions} sanitize>

              {/* Format */}
              <Form.Field>
                <label htmlFor="inputConfigurationFormat">Format</label>
                <Selection id="inputConfigurationFormat" name="format" options={formats} selection value={equipments.format} search={false} />
              </Form.Field>

              <Form.Field>

                <Form.Group widths="equal">

                  {/* Dosimeters */}
                  <Form.Field className="equipment-col">
                    <label>Dosimètres</label>

                    {/* Count */}
                    <Input type="number" id="inputEquipmentDosimeterCount" name="dosimeters_count" value={equipments.dosimeters_count} placeholder="0" />

                    {/* Version */}
                    <Input type="text" id="inputEquipmentDosimeterFirmware" name="dosimeters_firmware" value={equipments.dosimeters_firmware} placeholder="Version firmware" />
                  </Form.Field>

                  {/* Sources */}
                  <Form.Field className="equipment-col">
                    <label>Sources</label>

                    {/* Count */}
                    <Input type="number" id="inputEquipmentSourceCount" name="sources_count" value={equipments.sources_count} placeholder="0" />

                    {/* Version */}
                    <Input type="text" id="inputEquipmentSourceFirmware" name="sources_firmware" value={equipments.sources_firmware} placeholder="Version firmware" />
                  </Form.Field>

                  {/* Radiameters */}
                  <Form.Field className="equipment-col">
                    <label>Radiamères</label>

                    {/* Count */}
                    <Input type="number" id="inputEquipmentRadiameterCount" name="radiameters_count" value={equipments.radiameters_count} placeholder="0" />

                    {/* Version */}
                    <Input type="text" id="inputEquipmentRadiameterFirmware" name="radiameters_firmware" value={equipments.radiameters_firmware} placeholder="Version firmware" />
                  </Form.Field>

                </Form.Group>

              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalEditDosicaseConfiguration.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalEditDosicaseConfiguration;
