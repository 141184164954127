import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, Selection, Text } from 'components';

import { Form, Modal, Header, TransitionablePortal } from 'semantic-ui-react'

import { API } from 'services';
import { Typeof, Countries, Locales } from 'utils';

class ModalCustomer extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.isEdit = Typeof.object(props.customer);

    let state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: props.onClose
        }
      },

      icon: 'add',
      title: 'Nouveau client',
      customer: {},
      method: API.post,
      route: '/customers'
    };

    if(this.isEdit) {

      state = {
        ...state,
        icon: 'edit',
        title: 'Modification client',
        customer: props.customer,
        method: API.put,
        route: `/customers/${props.customer.id}`
      };
    }

    this.state = state;
  }

  handleSubmit(data, onSucess, onError) {

    this.state.method(this.state.route, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    let countries = Countries.ALL.map((country) => {
      return {
        key: country.code,
        text: country.name,
        value: country.code,
        flag: country.code.toLowerCase()
      };
    });

    let locales = Locales.ALL.map((locale) => {
      return {
        key: locale,
        text: locale.name,
        value: locale.code,
        flag: locale.flag.toLowerCase()
      };
    });

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon={this.state.icon} content={this.state.title} color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions}>

              <Form.Group widths='equal'>

                {/* Lastname */}
                <Form.Field>
                  <label htmlFor="inputLastname" className="required">Nom</label>
                  <Text style="uppercase">
                    <Input id="inputLastname" name="lastname" value={this.state.customer.lastname} />
                  </Text>
                </Form.Field>

                {/* Firstname */}
                <Form.Field>
                  <label htmlFor="inputFirstname" className="required">Prénom</label>
                  <Text style="capitalize">
                    <Input id="inputFirstname" name="firstname" value={this.state.customer.firstname} />
                  </Text>
                </Form.Field>

              </Form.Group>

              <Form.Group widths='equal'>

                {/* Email */}
                <Form.Field>
                  <label htmlFor="inputEmail" className="required">Adresse e-mail</label>
                  <Input id="inputEmail" name="email" value={this.state.customer.email} />
                </Form.Field>

                {/* Phone */}
                <Form.Field>
                  <label htmlFor="inputPhone">Numéro de téléphone</label>
                  <Input id="inputPhone" name="phone" value={this.state.customer.phone} />
                </Form.Field>

              </Form.Group>

              {/* Company */}
              <Form.Field>
                <label htmlFor="inputCompany" className="required">Société</label>
                <Text style="capitalize">
                  <Input id="inputCompany" name="company" value={this.state.customer.company} />
                </Text>
              </Form.Field>

              {/* Address */}
              <Form.Field>
                <label htmlFor="inputAddress" className="required">Adresse</label>
                <Input id="inputAddress" name="address" value={this.state.customer.address} />
              </Form.Field>

              <Form.Group widths='equal'>

                {/* Zip code */}
                <Form.Field>
                  <label htmlFor="inputZipCode" className="required">Code postal</label>
                  <Input id="inputZipCode" name="zip_code" value={this.state.customer.zip_code} />
                </Form.Field>

                {/* City */}
                <Form.Field>
                  <label htmlFor="inputCity" className="required">Ville</label>
                  <Input id="inputCity" name="city" value={this.state.customer.city} />
                </Form.Field>

              </Form.Group>

              <Form.Group widths='equal'>

                {/* Country */}
                <Form.Field>
                  <label htmlFor="inputCountry" className="required">Pays</label>
                  <Selection id="inputCountry" name="country" options={countries} clearable value={this.state.customer.country} />
                </Form.Field>

                {/* Locale */}
                <Form.Field>
                  <label htmlFor="inputLocale" className="required">Langue</label>
                  <Selection id="inputLocale" name="locale" options={locales} value={this.state.customer.locale} />
                </Form.Field>

              </Form.Group>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalCustomer.propTypes = {
  onClose: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired
  })
};

export default ModalCustomer;
