import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

import { Dimmer, Loader, Feed, Icon, Breadcrumb } from 'semantic-ui-react';
import { InfosBar } from 'components';

import { Typeof } from 'utils';

import { API } from 'services';

import moment from 'moment';

import './style.css';

const REGEX_LINK = /(<Link to="([^"]+)">([^<]+)<\/Link>)/;

class EventFeed extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      events: [],
      refresh: true,
      max: props.max
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.onRefresh = this.onRefresh.bind(this);

    this.handleShowMore = this.handleShowMore.bind(this);
    this.handleShowAll = this.handleShowAll.bind(this);
  }

  componentDidMount() {
    this.onRefresh();
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.filter !== this.props.filter) {
      this.handleRefresh();
    }

    if(!prevState.refresh && this.state.refresh) {
      this.onRefresh();
    }
  }

  handleRefresh() {
    this.setState({
      refresh: true
    });
  }

  onRefresh() {

    let params = {
      'limit': this.state.max
    };

    if(this.props.filter !== EventFeed.FILTER_ALL) {
      params['filter'] = this.props.filter;
    }

    API.get(`${this.props.route}${API.queryParams(params)}`)
      .then((response) => {

        let events = response.data.map((event) => {

          let data = this.props.mapper(event);
          let summary = [];

          if(!Typeof.object(data)) {
            data = {
              icon: 'help',
              summary: `Événement #${event.type} inconnu`
            };
          }

          let parts = data.summary.split(REGEX_LINK);

          for(let i = 0; i < parts.length; i++) {

            let part = parts[i];

            if(part.match(REGEX_LINK)) {

              //Link
              let target = parts[i + 1];
              let text = parts[i + 2];

              summary.push(<Link key={i} to={target}>{text}</Link>);
              i += 2;
            } else {

              //Simple text
              summary.push(part);
            }
          }

          //Add extra
          if(Typeof.string(data.extra)) {
            summary.push(<span className="extra">{data.extra}</span>);
          }

          return {
            ...data,
            timestamp: moment(event.timestamp).format('[Le] DD/MM/YYYY [à] HH:mm:ss'),
            summary: summary
          };
        });

        this.setState({
          events: events,
          refresh: false
        })

      });
  }

  handleShowMore() {
    this.setState({
      refresh: true,
      max: this.state.max + this.props.max
    });
  }

  handleShowAll() {

  }

  render() {

    let events = this.state.events;

    return (
      <React.Fragment>

        {/* Events */}
        {events.length > 0 ? (

          <React.Fragment>

            <Feed>

              {/* Events */}
              {events.map((event, index) => {

                return (
                  <Feed.Event key={index}>

                    {/* Icon */}
                    <Feed.Label>

                      {Typeof.string(event.icon) ? (
                        <Icon name={event.icon} />
                      ) : (
                        event.icon
                      )}

                    </Feed.Label>

                    <Feed.Content>

                      {/* Timestamp */}
                      <Feed.Date>{event.timestamp}</Feed.Date>

                      {/* Summary */}
                      <Feed.Summary>{event.summary}</Feed.Summary>
                    </Feed.Content>

                  </Feed.Event>
                );
              })}

            </Feed>

            {/* Actions */}
            <InfosBar mode="row">
              <Breadcrumb>
                <Breadcrumb.Section link onClick={this.handleShowMore}>Voir plus</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section link onClick={this.handleRefresh}>Actualiser</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section link onClick={this.handleShowAll}>Voir tout</Breadcrumb.Section>
              </Breadcrumb>
            </InfosBar>

          </React.Fragment>

        ) : (
          <InfosBar mode="row">
            <span>Aucune activité récente</span>
            <Breadcrumb>
              <Breadcrumb.Section link onClick={this.handleRefresh}>Actualiser</Breadcrumb.Section>
            </Breadcrumb>
          </InfosBar>
        )}

        {/* Dimmer */}
        {this.state.refresh &&
          <Dimmer active inverted>
            <Loader inverted content="Actualisation" />
          </Dimmer>
        }

      </React.Fragment>
    );
  }
}

//--Constants
EventFeed.TYPE_ACTIVATION      = 0x0;
EventFeed.TYPE_DESACTIVATION   = 0x1;
EventFeed.TYPE_INSTALLATION    = 0x2;
EventFeed.TYPE_SYNCHRONIZATION = 0x3;
EventFeed.TYPE_BACKUP_IMPORT   = 0x4;
EventFeed.TYPE_BACKUP_EXPORT   = 0x5;
EventFeed.TYPE_ASSOCIATION     = 0x6;
EventFeed.TYPE_DISSOCIATION    = 0x7;

EventFeed.FILTER_ALL = 'all';
EventFeed.FILTERS = [
  {
    key: 0,
    text: 'Tout',
    value: EventFeed.FILTER_ALL
  },
  {
    key: 1,
    text: 'Activation',
    value: EventFeed.TYPE_ACTIVATION
  },
  {
    key: 2,
    text: 'Désactivation',
    value: EventFeed.TYPE_DESACTIVATION
  },
  {
    key: 3,
    text: 'Installation',
    value: EventFeed.TYPE_INSTALLATION
  },
  {
    key: 4,
    text: 'Synchronisation',
    value: EventFeed.TYPE_SYNCHRONIZATION
  },
  {
    key: 5,
    text: 'Sauvegarde (import)',
    value: EventFeed.TYPE_BACKUP_IMPORT
  },
  {
    key: 6,
    text: 'Sauvegarde (export)',
    value: EventFeed.TYPE_BACKUP_EXPORT
  },
  {
    key: 7,
    text: 'Association',
    value: EventFeed.TYPE_ASSOCIATION
  },
  {
    key: 8,
    text: 'Dissociation',
    value: EventFeed.TYPE_DISSOCIATION
  }
];

EventFeed.filter = (events, filter) => {

  return events.filter((event) => {
    return filter === EventFeed.FILTER_ALL || event.type === filter;
  });
};

export default EventFeed;
