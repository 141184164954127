import { API } from 'services';
import { Typeof } from 'utils';

//--Constants
const TOKEN = "token";

class Auth {

  //--Constants
  static ROLE_API = 'ROLE_API';
  static ROLE_ADMIN = 'ROLE_ADMIN';

  /**
   * Indicates if the user is logged
   * @return {boolean} true if the user is logged otherwise false
   */
  static isLogged() {
    return Auth.user !== null;
  }

  /**
   * Gets the current logged user
   * @returns {object|null} the current logged user or null
   */
  static getUser() {
    return Auth.user;
  }

  static getToken() {
    return localStorage.getItem(TOKEN);
  }

  static onAuth(user, token) {
    Auth.user = user;

    if(Typeof.string(token)) {
      localStorage.setItem(TOKEN, token);
    } else {
      localStorage.removeItem(TOKEN);
    }

    API.setToken(token);
  }

  /**
   * Check the latest logged user
   * @return {Promise}
   */
  static checkToken() {

    return new Promise((resolve, reject) => {

      let token = Auth.getToken();

      if(Typeof.string(token)) {

        API.get('users/token', {'token': token})
          .then((response) => {

            Auth.onAuth(response.data, token);
            resolve();
          })
          .catch(() => {

            Auth.onAuth(null, token);
            reject();
          });

      } else {

        //No previous auth
        reject();
      }

    });
  }

  /**
   * Login with the given credentials
   * @param {string} email
   * @param {string} password
   */
  static login(email, password) {

    return new Promise((resolve, reject) => {

      //Login
      API.post('users/login', { email, password })
      .then((response) => {

        let data = response.data;

        Auth.onAuth(response.data.user, data.token);
        resolve();
      })
      .catch(reject);

    });

  }

  /**
   * Logout the current logged user.
   */
  static logout() {
    return new Promise((resolve, reject) => {

      API.post('/users/logout')
      .then(() => {

        Auth.onAuth(null, null);
        resolve();
      })
      .catch(reject);

    });
  }


  static hasRole(roles) {

    if(Auth.isLogged()) {

      if(Typeof.string(roles)) {
        roles = [roles];

        if(Typeof.array(roles)) {

          //Fix roles
          roles = roles.map((role) => role.trim());

          return roles.includes(Auth.user.role) || Auth.user.role === Auth.ROLE_ADMIN;
        }
      }

    }

    return false;
  }

}

//Set default
Auth.user = null;

export default Auth;
