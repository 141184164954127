import { Typeof } from "utils";

/**
 * Utilities functions.
 *
 * @author Matthieu RENAULT <matthieu.renault@ob-do.com>
 */
class Functions {

  /**
   * Capitalizes the given string.
   * @param {String} string the string to capitalizes
   * @return the capitalized string
   */
  static capitalize(string) {

    if (Typeof.string(string) && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return string;
  }
}

export default Functions;
