import React from 'react'
import PropTypes from 'prop-types'

import { Section, InfosBar, Table, Empty, Properties, ContractDate, FileActions } from 'components';
import { Button, Container } from 'semantic-ui-react';

import { ModalCreateDosicaseContract, ModalOptions } from 'modals';

import { API } from 'services';

import { Typeof, Constants } from 'utils';
import moment from 'moment';

class PaneContracts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      targetContract: null,
      showCreateModal: false,
      showDeleteModal: false
    };

    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.handleCloseCreateModal = this.handleCloseCreateModal.bind(this);

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);

    this.getSecurityQuestion = this.getSecurityQuestion.bind(this);
  }

  toggleCreateModal() {

    //Show modal
    this.setState({
      showCreateModal: !this.state.showCreateModal
    });
  }

  handleCloseCreateModal(contract) {

    if (Typeof.object(contract)) {

      //Update locally
      this.props.dosicase.contracts.push(contract);
      this.forceUpdate();
    }

    //Hide modal
    this.toggleCreateModal();
  }

  toggleDeleteModal(contract = null) {

    //Show modal
    this.setState({
      targetContract: contract,
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  handleCloseDeleteModal(result, setProcessing) {

    if (result !== ModalOptions.OPTION_YES) {
      this.toggleDeleteModal();
    } else {

      setProcessing(true);

      API.delete(`/dosicases/contracts/${this.state.targetContract.id}`)
        .then(() => {

          //Remove locally
          this.props.dosicase.contracts = this.props.dosicase.contracts.filter((contract) => contract.id !== this.state.targetContract.id);
          this.forceUpdate();

          this.toggleDeleteModal();
        });
    }
  }

  getSecurityQuestion(question, anwser) {

    return (
      <div className="security-question">

        {Typeof.number(question) && Typeof.string(anwser) ? (
          <React.Fragment>
            <span className="question">{Constants.getSecurityQuestion(question)}</span>
            <span className="answer">{anwser}</span>
          </React.Fragment>
        ) : (
            <Empty />
          )}

      </div>
    )

  }

  render() {

    const { dosicase } = this.props;

    let previousContracts = dosicase.contracts.filter((contract, index) => {
      return index < (dosicase.contracts.length - 1);
    });

    let currentContractProperties = [];

    if (dosicase.contracts.length > 0) {
      let contract = dosicase.contracts[dosicase.contracts.length - 1];

      currentContractProperties = [
        {
          name: "Date d'ajout",
          value: moment(contract.timestamp).format('[Le] DD/MM/YYYY [à] HH:mm:ss')
        },
        {
          name: "Date d'échéance",
          value: <ContractDate contract={contract} />
        },
        {
          name: "Actions",
          value: (
            <FileActions endpoint={`dosicases/contracts/${contract.id}`} actions={[
              ...FileActions.DEFAULT_ACTIONS,
              {
                icon: 'trash',
                content: 'Supprimer',
                handle: () => this.toggleDeleteModal(contract),
                className: 'contract-delete'
              }
            ]} />
          )
        }
      ];
    }

    let columns = [
      {
        id: 'timestamp',
        header: "Date d'ajout",
        value: (contract) => moment(contract.timestamp).format('DD/MM/YYYY HH:mm:ss'),
        render: (contract) => moment(contract.timestamp).format('[Le] DD/MM/YYYY [à] HH:mm:ss')
      },
      {
        id: 'due_date',
        header: "Date d'échéance",
        value: (contract) => moment(contract.due_date).format('DD/MM/YYYY'),
        render: (contract, value) => <span>{value}</span>
      },
      {
        id: 'actions',
        header: "Actions",
        value: (contract) => undefined,
        render: (contract) => (
          <FileActions endpoint={`dosicases/contracts/${contract.id}`} actions={[
            ...FileActions.DEFAULT_ACTIONS,
            {
              icon: 'trash',
              content: 'Supprimer',
              handle: () => this.toggleDeleteModal(contract),
              className: 'contract-delete'
            }
          ]} />
        ),
        fit: true,
      }
    ];

    return (

      <React.Fragment>

        {/* Current contract */}
        <Container>
          <Section title="Contrat actuel" actions={
            <Button icon="add" color="yellow" onClick={this.toggleCreateModal} content="Nouveau" />
          }>

            {currentContractProperties.length > 0 ? (
              // Current contract
              <Properties properties={currentContractProperties} />
            ) : (
                // No contrat available
                <InfosBar mode="row">
                  <span>Aucun contrat actuel</span>
                </InfosBar>
              )}

          </Section>

        </Container>

        {/* Previous contracts */}
        <Container>
          <Section title="Contrats précédents">

            {previousContracts.length > 0 ? (

              <React.Fragment>

                {/* Number of previous contracts */}
                <InfosBar mode="column">
                  <span>{previousContracts.length > 1 ? `${previousContracts.length} contrats précédents` : '1 contrat précédent'}</span>
                </InfosBar>

                {/* Previous contracts list */}
                <Table columns={columns}
                  items={previousContracts}
                />

              </React.Fragment>

            ) : (

                // No previous contrat
                <InfosBar mode="row">
                  <span>Aucun contrat précédent</span>
                </InfosBar>

              )}

          </Section>
        </Container>

        {/* Contract creation */}
        {this.state.showCreateModal &&
          <ModalCreateDosicaseContract onClose={this.handleCloseCreateModal} dosicase={dosicase} />
        }

        {/* Contract delete warning modal */}
        {this.state.showDeleteModal &&
          <ModalOptions onClose={this.handleCloseDeleteModal} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de supprimer ce contrat.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

      </React.Fragment>

    );
  }
}

export default PaneContracts;
