import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, Text } from 'components';

import { Form, Modal, Header, TransitionablePortal } from 'semantic-ui-react'

import { API } from 'services';
import { Typeof } from 'utils';

class ModalDosicaseContact extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.isEdit = Typeof.object(props.contact);

    let state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      },

      icon: 'add',
      title: 'Nouveau contact',
      contact: {},
      action: API.post,
      route: `/dosicases/${this.props.dosicase.id}/contacts`
    };

    if(this.isEdit) {

      state = {
        ...state,
        icon: 'edit',
        title: 'Modification contact',
        contact: this.props.contact,
        action: API.put,
        route: `/dosicases/contacts/${this.props.contact.id}`
      };
    }

    this.state = state;
  }

  handleSubmit(data, onSucess, onError) {


    if(this.isEdit) {
      data.id = this.state.contact.id
    };

    this.state.action(this.state.route, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon={this.state.icon} content={this.state.title} color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions} sanitize>

              <Form.Field>

                <Form.Group widths='equal'>

                  {/* Lastname */}
                  <Form.Field>
                    <label htmlFor="inputLastname" className="required">Nom</label>
                    <Text style="uppercase">
                      <Input id="inputLastname" name="lastname" value={this.state.contact.lastname} />
                    </Text>
                  </Form.Field>

                  {/* Firstname */}
                  <Form.Field>
                    <label htmlFor="inputFirstname" className="required">Prénom</label>
                    <Text style="capitalize">
                      <Input id="inputFirstname" name="firstname" value={this.state.contact.firstname} />
                    </Text>
                  </Form.Field>

                </Form.Group>

              </Form.Field>

              <Form.Group widths='equal'>

                {/* Email */}
                <Form.Field>
                  <label htmlFor="inputEmail" className="required">Adresse e-mail</label>
                  <Input id="inputEmail" name="email" value={this.state.contact.email} />
                </Form.Field>

                {/* Phone */}
                <Form.Field>
                  <label htmlFor="inputPhone">Numéro de téléphone</label>
                  <Input id="inputPhone" name="phone" value={this.state.contact.phone} />
                </Form.Field>

              </Form.Group>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalDosicaseContact.propTypes = {
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired
  })
};

export default ModalDosicaseContact;
