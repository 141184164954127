import dev from './dev.js';
import prod from './prod.js';

let target = process.env.NODE_ENV === 'production' ? prod : dev;

class Env {
  // Build
  static BUILD_VERSION = process.env.REACT_APP_VERSION;
  static BUILD_HASH    = '495d2b3ba8d16606711ef809a4bfb5e970c0ed41';
  static BUILD_DATE    = '01-03-2022 14h15';

  // API
  static HOST = target.HOST;
  static PORT = target.PORT;

  // Mantis
  static MANTIS_URL        = "https://37.58.201.35:18298" //process.env.MANTIS_URL;
  static MANTIS_PROJECT_ID = 1                            //process.env.MANTIS_PROJECT_ID;
}

export { Env }
