import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input } from 'components';

import { Form, Modal, Header, TransitionablePortal } from 'semantic-ui-react'

import { API } from 'services';
import { Constants } from 'utils';

class ModalEditDosicase extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      }
    };
  }

  handleSubmit(data, onSucess, onError) {

    API.put(`/dosicases/${this.props.dosicase.id}`, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    let dosicase = this.props.dosicase;

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size="small">
          <Header icon="edit" content="Modification valise" color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions}>

              {/* Inbox mail */}
              <Form.Field>
                <label htmlFor="inputInboxEmail" className="required">Adresse mail de messagerie</label>
                <Input id="inputInboxEmail" name="inbox_email" value={dosicase.inbox_email} placeholder={Constants.getDosicaseAddress(dosicase.id)} />
              </Form.Field>

              {/* Inbox password */}
              <Form.Field>
                <label htmlFor="inputInboxPassword" className="required">Mot de passe de messagerie</label>
                <Input id="inputInboxPassword" name="inbox_password" value={dosicase.inbox_password} />
              </Form.Field>

              {/* Delivery date */}
              <Form.Field>
                <label htmlFor="inputDeliveryDate">Date de livraison</label>
                <Input type="date" id="inputDeliveryDate" name="delivery_date" value={dosicase.delivery_date} />
              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalEditDosicase.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalEditDosicase;
