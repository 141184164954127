import React from 'react'
import PropTypes from 'prop-types'

import { Button, Icon } from 'semantic-ui-react';

class ButtonBar extends React.Component {

  render() {

    return (
      <Button.Group color="yellow">

        {/* Buttons */}
        {this.props.modes.map((mode, index) => {

          return (
            <Button icon active={this.props.current === index} onClick={() => this.props.onChange(index)} key={index}>
              <Icon color="black" name={mode} />
            </Button>
          )

        })}

      </Button.Group>
    );
  }
}

ButtonBar.defaultProps = {
  current: 0,
  modes: ['list layout', 'block layout', 'grid layout']
};

ButtonBar.propTypes = {
  current: PropTypes.oneOf([0, 1, 2]).isRequired,
  onChange: PropTypes.func.isRequired,
  modes: PropTypes.arrayOf(PropTypes.string)
};

export default ButtonBar;
