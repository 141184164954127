import React from 'react'
import PropTypes from 'prop-types'

import { Header, List } from 'semantic-ui-react';

import { Typeof } from 'utils';

import './style.css';

class Properties extends React.Component {
  render() {

    let title = this.props.title;

    return (
      <div className="section">

        {/* Title */}
        {title &&
          <Header as='h3' attached='top'>
            {this.props.title}
          </Header>
        }

        {/* Rows */}
        <List relaxed bulleted divided>
          {this.props.properties.map((row, index) => {

            let name = Typeof.func(row.name) ? row.name() : row.name;
            let value = Typeof.func(row.value) ? row.value() : row.value;

            return (
              <List.Item key={index}>
                <List.Content>{name}</List.Content>

                {value}

              </List.Item>
            )

          })}
        </List>
      </div>
    );
  }
}

Properties.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    value: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
  })).isRequired
};

export default Properties;
