import React from 'react'
import PropTypes from 'prop-types'

import { Container, Card, Segment } from 'semantic-ui-react';
import { Section, ButtonBar, InfosBar, Option } from 'components';

import { BreadcrumbHelper } from 'utils';
import { API } from 'services';

class OptionScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      optionsDisplayMode: 0,
      optionsCriteria: ''
    };

    this.handleOptionSearch = this.handleOptionSearch.bind(this);
    this.handleOptionDisplayChange = this.handleOptionDisplayChange.bind(this);
    this.handleOptionValue = this.handleOptionValue.bind(this);
  }

  componentWillMount() {
    this.updateBreadcrumb();
  }

  updateBreadcrumb() {
    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Options');
    BreadcrumbHelper.flush();
  }

  handleOptionSearch(criteria) {
    this.setState({
      optionsCriteria: criteria
    });
  }

  handleOptionDisplayChange(current) {

    this.setState({
      optionsDisplayMode: current
    });
  }

  handleOptionValue(option, value, onComplete) {

    API.put(`/options/${option.id}`, { value: value }).then(() => {

      option.default_value = `${value}`;
      this.forceUpdate();

    }).finally(onComplete);
  }

  render() {

    let criteria = this.state.optionsCriteria;
    let hasFilter = criteria.length > 0;

    let options = this.props.options;

    //Filter options
    if(hasFilter) {
      options = options.filter((option) => {

        return option.id.includes(criteria)
          || option.name.toLowerCase().includes(criteria.toLowerCase())
          || option.description.toLowerCase().includes(criteria.toLowerCase());
      });
    }

    return (
      <Container>

        <Section title="Liste des options" onSearch={this.handleOptionSearch} actions={
          // Display mode bar
          <ButtonBar current={this.state.optionsDisplayMode} onChange={this.handleOptionDisplayChange} />
        }>

          {this.props.options.length > 0 ? (

            <React.Fragment>

              {/* Options informations */}
              <InfosBar>
                <span>{this.props.options.length > 1 ? `${this.props.options.length} options disponibles` : '1 option disponible'}</span>
              </InfosBar>

              {options.length > 0 ? (

                // Options cards
                <Card.Group itemsPerRow={this.state.optionsDisplayMode + 1}>
                  {options.map((option) => {

                    let _option = {
                      ...option,
                      default_value: undefined
                    };

                    return <Option key={option.id} option={_option} active={option.active} value={option.default_value} onValueChange={this.handleOptionValue} strict />
                  })}
                </Card.Group>

              ) : (

                // No options found for criteria
                <Segment>
                  <InfosBar mode="row">
                    <span>Aucune option ne correspond à votre recherche</span>
                  </InfosBar>
                </Segment>

              )}

            </React.Fragment>

          ) : (

            // No options available
            <InfosBar mode="row">
              <span>Aucune option n'est disponible</span>
            </InfosBar>

          )}

        </Section>

      </Container>
    );
  }
}

export default OptionScene;
