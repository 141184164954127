import React from 'react'
import PropTypes from 'prop-types'

class TextArea extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {

    this.setState({
      value: event.target.value
    });
  }

  render() {

    let { value, ...rest } = this.props;

    return (
      <textarea {...rest} value={this.state.value} onChange={this.handleChange} />
    );
  }
}

export default TextArea;
