import React from 'react'

import { Dimmer, Progress } from 'semantic-ui-react';

import { Typeof } from 'utils';

import './container.css';

//--Constants

/**
 * Default group for task
 * @type {Number}
 */
const GROUP_DEFAULT = -1;

/**
 * Component container used to process async task (API request) while displaying progress bar
 *
 * @extends React
 */
class AbstractContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      running: false,
      complete: 0,
      results: [],
      currentGroup: null,
      tasks: []
    };
  }

  getResult(key) {
    return this.state.results[key];
  }

  componentWillMount() {

    let tasks = this.getTasks(this.props.match.params);

    if(Typeof.array(tasks)) {

      //Sort tasks by group
      let tasksByGroup = [];
      let groups = [];

      tasks.forEach((task) => {
        let group = (Typeof.number(task.group) ? task.group : GROUP_DEFAULT).toString();

        if(!groups.includes(group)) {
          groups.push(group);
        }

        let groupIndex = groups.indexOf(group);

        if(!Typeof.array(tasksByGroup[groupIndex])) {
          tasksByGroup[groupIndex] = [];
        }

        tasksByGroup[groupIndex].push(task);
      });

      this.setState({
        running: true,
        tasks: tasksByGroup,
        currentGroup: 0
      }, () => {
        this.start(this.state.currentGroup);
      });

    }
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.state.running && prevState.currentGroup !== this.state.currentGroup) {

      if(this.state.currentGroup >= this.state.tasks.length) {
        this.setState({
          running: false
        });
      } else {
        this.start(this.state.currentGroup);
      }

    }
  }

  start(group) {

    let tasks = this.state.tasks[group];
    let complete = 0;

    let tasksResults = {};

    //Called on task completion
    let onTaskComplete = (task, result) => {

      //Register task result
      tasksResults[task.key] = result;
      complete++;

      let state = {
        complete: this.state.complete + 1
      };

      //All tasks for the current group are complete
      if(complete === tasks.length) {

        //Update global results with groups results
        let results = this.state.results;

        Object.keys(tasksResults).forEach((key) => {
          results[key] = tasksResults[key];
        });

        //Launch next group
        state.results = results;
        state.currentGroup = group + 1;
      }

      this.setState(state);
    };

    tasks.forEach((task) => {

      try {
        //Execute task
        task.exec((result) => {

          //Register task completion
          onTaskComplete(task, result)
        }, this.onTaskFailure);
      } catch(error) {
        console.log(error);
      }

    });

  }

  getTasks() {
    return [];
  }

  onTaskFailure(error) {

  }

  getComponent() {
    throw new Error('Not implemented');
  }

  render() {

    if(this.state.running) {

      //Get the total amout of tasks
      let total = this.state.tasks.reduce((total, group) => {
        return total + group.length;
      }, 0);

      return (
        <Dimmer active inverted className="dimmer-loading">
          <Progress value={this.state.complete} total={total} progress={'ratio'} color='yellow' label='Chargement en cours...'/>
        </Dimmer>
      )
    }

    //Render component
    return this.getComponent(this.state.results)
  }
}

export default AbstractContainer;
