import React from 'react'
import PropTypes from 'prop-types'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { CustomerListScene } from 'scenes';

class CustomerListContainer extends AbstractContainer {

  getTasks() {

    return [
      {
        group: 1,
        key: 'customers',
        exec: (done, failure) => {
          API.get('customers')
          .then((response) => {
            done(response.data);
          })
          .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <CustomerListScene customers={results.customers} />
  }
}

export default CustomerListContainer;
