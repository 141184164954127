import React from 'react'
import PropTypes from 'prop-types'

import compareVersions from 'compare-versions';

import { Section, ButtonBar, InfosBar, Option } from 'components';
import { Dimmer, Card, Segment, Loader, Dropdown, Popup, Label, Container } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { API } from 'services';
import { Typeof, Constants } from 'utils';

import './style.css';

class PaneOptions extends React.Component {

  constructor(props) {
    super(props);

    let currentVersion = undefined;

    if(this.props.versions.length > 0){

      if(Typeof.string(this.props.dosicase.version)) {

        //Select the current dosicase version
        currentVersion = this.props.dosicase.version;
      } else {

        //Select the last available version
        currentVersion = this.props.versions[this.props.versions.length - 1].id;
      }
    }

    this.state = {
      versions: this.props.versions.sort((a, b) => compareVersions(a.id, b.id)),
      currentVersion: currentVersion,
      fetchingOptions: true,
      optionsCriteria: '',
      optionsDisplayMode: 0,
      availableOptions: [],
      enabledOptions: [],
    };

    this.handleFilterVersion = this.handleFilterVersion.bind(this);

    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.handleOptioneSearch = this.handleOptioneSearch.bind(this);
    this.handleOptionButtonBarChange = this.handleOptionButtonBarChange.bind(this);

    this.handleOptionToggle = this.handleOptionToggle.bind(this);
    this.handleOptionValue = this.handleOptionValue.bind(this);
  }

  componentDidMount() {
    this.handleVersionChange();
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.currentVersion !== this.state.currentVersion) {
      this.handleVersionChange();
    }
  }

  handleVersionChange() {

    if(Typeof.string(this.state.currentVersion)) {
      this.setState({
        fetchingOptions: true
      }, () => {

        Promise.all([

          //Get options for version
          API.get(`/versions/${this.state.currentVersion}/options`),

          //Get options for dosicase version
          API.get(`/dosicases/${this.props.dosicase.id}/versions/${this.state.currentVersion}/options`)
        ]).then((responses) => {

          let versionOptions = responses[0].data;
          let selectedOptions = responses[1].data;

          //Get available options for version
          let availableOptions = [];
          this.props.options.forEach((option) => {

            let optionLink = versionOptions.find((optionLink) => option.id === optionLink.option);

            if (Typeof.object(optionLink)) {

              availableOptions.push({
                id: option.id,
                data_type: option.data_type,
                name: option.name,
                description: option.description,
                default_value: Typeof.string(optionLink.value_overwrite) ? optionLink.value_overwrite : option.default_value
              });

            }

          });

          this.setState({
            availableOptions: availableOptions,
            selectedOptions: selectedOptions,
            fetchingOptions: false
          });

        });

      });
    }
  }

  handleFilterVersion(event, { value }) {

    this.setState({
      currentVersion: value
    });
  }

  handleOptioneSearch(criteria) {

    this.setState({
      optionsCriteria: criteria
    });
  }

  handleOptionButtonBarChange(current) {

    this.setState({
      optionsDisplayMode: current
    });
  }

  handleOptionToggle(option, checked, onComplete) {

    let route = `/dosicases/${this.props.dosicase.id}/versions/${this.state.currentVersion}/options/${option.id}`;

    let request = checked ? API.post(route) : API.delete(route);
    request.then(() => {

      let selectedOptions = this.state.selectedOptions;

      if (checked) {

        //Associate
        selectedOptions.push({ option: option.id });
      } else {

        //Dissociate
        selectedOptions = selectedOptions.filter((_option) => {
          return _option.option !== option.id
        });
      }

      this.setState({
        selectedOptions: selectedOptions
      })

    }).catch((error) => {
      //
    }).finally(onComplete);
  }

  handleOptionValue(option, value, onComplete) {

    API.put(`/dosicases/${this.props.dosicase.id}/versions/${this.state.currentVersion}/options/${option.id}`, { value: value }).then(() => {

      //Get option
      option = this.state.selectedOptions.find((_option) => option.id === _option.id);

      //Update value
      if (Typeof.object(option)) {

        if (Typeof.defined(value)) {
          value = `${value}`;
        }

        option.value_overwrite = value;
      }

      this.forceUpdate();

    }).finally(onComplete);
  }

  render() {

    const { dosicase } = this.props;

    //Options

    let versionsFilter = this.state.versions.map((version, index) => {

      let infos = Constants.getVersionStateInfos(version.state);

      return {
        key: index,
        value: version.id,
        text: (
          <span className="version">

            {/* State */}
            <Popup
              trigger={<Label circular empty color={infos.color} />}
              content={infos.label}
              inverted
              position="bottom left"
            />

            {/* Version number */}
            {version.id}

            {/* Current version indicator */}
            {dosicase.version === version.id &&
              <span className="current-version">actuelle</span>
            }
          </span>
        )
      }
    }).reverse();

    let optionsTitle = (
      <span>Options de la version <Dropdown scrolling disabled={this.state.fetchingOptions} value={this.state.currentVersion} options={versionsFilter} onChange={this.handleFilterVersion} /></span>
    );

    let criteria = this.state.optionsCriteria;
    let hasFilter = Typeof.string(criteria) && criteria.trim().length > 0;

    let optionsDisabled = this.state.availableOptions.length === 0 || this.state.fetchingOptions;

    let options = this.state.availableOptions.filter((option) => {
      return !hasFilter || option.id.includes(criteria) || option.name.toLowerCase().includes(criteria.toLowerCase()) || option.description.toLowerCase().includes(criteria.toLowerCase());
    });

    return (
      <Container className="pane-options">

        {this.props.versions.length > 0 ? (

          <Section disabled={optionsDisabled} title={optionsTitle} onSearch={this.handleOptioneSearch} actions={[

            // Display mode bar
            <ButtonBar current={this.state.optionsDisplayMode} onChange={this.handleOptionButtonBarChange} />
          ]}>

            {this.state.fetchingOptions ? (
              <Dimmer active inverted>
                <Loader inverted content='Chargement des options' />
              </Dimmer>
            ) : (

                <React.Fragment>

                  {this.state.availableOptions.length > 0 ? (

                    <React.Fragment>

                      {/* Options informations */}
                      <InfosBar mode="column">
                        <span>{this.state.availableOptions.length > 1 ? `${this.state.availableOptions.length} options disponibles` : '1 option disponible'}</span>
                        <span>{this.state.selectedOptions.length === 0 ? 'Aucune option sélectionnée' : this.state.selectedOptions.length > 1 ? `${this.state.selectedOptions.length} options sélectionnées` : '1 option sélectionnée'}</span>
                      </InfosBar>

                      {options.length > 0 ? (

                        // Options cards
                        <Card.Group itemsPerRow={this.state.optionsDisplayMode + 1}>
                          {options.map((option) => {

                            let optionLink = this.state.selectedOptions.find((optionLink) => optionLink.option === option.id);
                            let selected = Typeof.object(optionLink);
                            let valueOverwrite = selected ? optionLink.value_overwrite : undefined;

                            return <Option key={option.id} option={option} active={selected} value={valueOverwrite} toggle onToggle={this.handleOptionToggle} onValueChange={this.handleOptionValue} />
                          })}
                        </Card.Group>

                      ) : (

                          // No options found for criteria
                          <Segment>
                            <InfosBar mode="row">
                              <span>Aucune option ne correspond à votre recherche</span>
                            </InfosBar>
                          </Segment>
                        )}

                    </React.Fragment>
                  ) : (

                      // No options available for current version
                      <InfosBar mode="row">
                        <span>Aucune option n'est disponible pour cette version</span>
                        <Link to={`/versions/${this.state.currentVersion}`}>Ajouter des options</Link>
                      </InfosBar>

                    )}

                </React.Fragment>
              )}

          </Section>

        ) : (

          // No version available
          <InfosBar mode="row">
            <span>Aucune version n'est disponible</span>
            <Link to="/versions">Ajouter des versions</Link>
          </InfosBar>

        )}

      </Container>
    );
  }
}

export default PaneOptions;
