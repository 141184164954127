import React from 'react'
import PropTypes from 'prop-types'

import { Section, Properties, Activation, Empty, Blurred, Text, TextGroup } from 'components';
import { Container, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import {
  SectionEquipments,
  SectionDevice,
  SectionAccount
} from './sections';

import { ModalOptions, ModalEditDosicase } from 'modals';

import { BreadcrumbHelper, Typeof } from 'utils';
import { API } from 'services';

import moment from 'moment';

class PaneInformations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showDosicaseEditModal: false,
      showDosicaseDissociateModal: false,
      showDosicaseRevokeModal: false
    };

    this.toggleDosicaseDissociateModal = this.toggleDosicaseDissociateModal.bind(this);
    this.handleCloseDosicaseDissociateModal = this.handleCloseDosicaseDissociateModal.bind(this);

    this.toggleDosicaseEditModal = this.toggleDosicaseEditModal.bind(this);
    this.handleCloseDosicaseEditModal = this.handleCloseDosicaseEditModal.bind(this);

    this.toggleDosicaseRevokeModal = this.toggleDosicaseRevokeModal.bind(this);
    this.handleCloseDosicaseRevokeModal = this.handleCloseDosicaseRevokeModal.bind(this);
  }

  componentWillMount() {
    this.updateBreadcrumb();
  }

  updateBreadcrumb() {

    //Update breadcrumb
    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');

    //Add customer part
    if (Typeof.object(this.props.dosicase.customer)) {
      BreadcrumbHelper.push('Clients', '/customers');
      BreadcrumbHelper.push(<Text style="capitalize">{`${this.props.dosicase.customer.company}`}</Text>, `/customers?s=${this.props.dosicase.customer.company}`)
      BreadcrumbHelper.push(
        <TextGroup>
          <Text style="uppercase">{this.props.dosicase.customer.lastname}</Text>
          <Text style="capitalize">{this.props.dosicase.customer.firstname}</Text>
        </TextGroup>
        , `/customers/${this.props.dosicase.customer.id}`);
    }

    BreadcrumbHelper.push(`Valise #${this.props.dosicase.id}`);
    BreadcrumbHelper.flush();
  }

  toggleDosicaseEditModal() {

    //Show modal
    this.setState({
      showDosicaseEditModal: !this.state.showDosicaseEditModal
    });
  }

  handleCloseDosicaseEditModal(dosicase) {

    if (Typeof.object(dosicase)) {

      //Update locally
      Object.keys(dosicase).forEach((key) => {
        this.props.dosicase[key] = dosicase[key];
      });

    }

    //Hide modal
    this.toggleDosicaseEditModal();
  }

  toggleDosicaseDissociateModal() {

    //Show modal
    this.setState({
      showDosicaseDissociateModal: !this.state.showDosicaseDissociateModal
    });
  }

  handleCloseDosicaseDissociateModal(result, setProcessing) {

    if (result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      API.delete(`/customers/${this.props.dosicase.customer.id}/dosicases/${this.props.dosicase.id}`)
        .then(() => {

          //Remove customer infos
          delete this.props.dosicase.customer;

          //Go back to customers list
          this.updateBreadcrumb();
          this.toggleDosicaseDissociateModal();
        });

    } else {

      this.toggleDosicaseDissociateModal();
    }
  }

  toggleDosicaseRevokeModal() {

    //Show modal
    this.setState({
      showDosicaseRevokeModal: !this.state.showDosicaseRevokeModal
    });

  }

  handleCloseDosicaseRevokeModal(result, setProcessing) {

    if (result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      API.delete(`/dosicases/${this.props.dosicase.id}/revoke`)
        .then((response) => {

          this.props.dosicase.activated = false;
          this.props.dosicase.activation_code = response.data.code;

          this.toggleDosicaseRevokeModal();
        });

    } else {

      this.toggleDosicaseRevokeModal();
    }
  }

  render() {

    const { dosicase, hasCustomer } = this.props;

    let dosicaseProperties = [
      {
        name: 'Numéro de produit',
        value: dosicase.id
      },
      {
        name: 'Adresse de boîte de réception',
        value: <a href={`mailto:${dosicase.inbox_email}`}>{dosicase.inbox_email}</a>
      },
      {
        name: 'Mot de passe de la boîte de réception',
        value: Typeof.string(dosicase.inbox_password) ? <Blurred value={dosicase.inbox_password} /> : <Empty />
      },
      {
        name: `Date de livraison`,
        value: Typeof.string(dosicase.delivery_date) ? moment(dosicase.delivery_date).format('[Le] DD/MM/YYYY') : <Empty />
      },
      {
        name: () => {
          return (
            <React.Fragment>
              Activation

              {/* Revoke link */}
              {dosicase.activated &&
                <Link className="activation-revoke" onClick={this.toggleDosicaseRevokeModal}>(Révoquer)</Link>
              }
            </React.Fragment>
          )
        },
        value: () => {
          return (
            <Activation code={dosicase.activation_code} activated={dosicase.activated} />
          )
        }
      },
      {
        name: "Version iOS",
        value: Typeof.string(dosicase.ios_version) ? <Text style="mono">{dosicase.ios_version}</Text> : <Empty />
      },
      {
        name: "Version de l'application",
        value: Typeof.string(dosicase.version) ? <Link to={`/versions/${dosicase.version}`}><Text style="mono">{dosicase.version}</Text></Link> : <Empty />
      },
      {
        name: "Date d'installation",
        value: Typeof.string(dosicase.install_date) ? moment(dosicase.install_date).format('[Le] DD/MM/YYYY [à] HH:mm:ss') : <Empty />
      }
    ];

    return (
      <React.Fragment>

        {/* Informations */}
        <Container>

          <Section title="Informations" actions={[
            <Button icon="edit" circular color="yellow" onClick={this.toggleDosicaseEditModal} key={0} />,
            hasCustomer ? <Button icon="unlink" circular color="red" onClick={this.toggleDosicaseDissociateModal} key={1} /> : null
          ]}>

            <Properties properties={dosicaseProperties} />

          </Section>

        </Container>

        <Container>

          {/* Equipments */}
          <SectionEquipments dosicase={dosicase} />

        </Container>

        <Container>

          {/* iPad */}
          <SectionDevice dosicase={dosicase} />

        </Container>

        <Container>

          {/* Apple ID */}
          <SectionAccount dosicase={dosicase} />

        </Container>


        {/* Dosicase edit modal */}
        {this.state.showDosicaseEditModal &&
          <ModalEditDosicase onClose={this.handleCloseDosicaseEditModal} dosicase={dosicase} />
        }

        {/* Dosicase dissociates warning modal */}
        {hasCustomer && this.state.showDosicaseDissociateModal &&
          <ModalOptions onClose={this.handleCloseDosicaseDissociateModal} icon="unlink" title="Dissocier" message={<span>Vous êtes sur le point de dissocier cette valise du client <b>{dosicase.customer.name}</b>.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

        {/* Dosicase revoke warning modal */}
        {this.state.showDosicaseRevokeModal &&
          <ModalOptions onClose={this.handleCloseDosicaseRevokeModal} icon="trash alternate" title="Révoquer" message={<span>Vous êtes sur le point de révoquer l'activation de cette valise.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

      </React.Fragment>
    );
  }
}

export default PaneInformations;
