let Constants = {

  VERSION_STATE_UNAVAILABLE : 0x0,
  VERSION_STATE_TESTING     : 0x1,
  VERSION_STATE_AVAILABLE   : 0x2,

  //--Synchronization status
  STATUS_SYNCHRONIZATION_SUCCESS : 'success',
  STATUS_SYNCHRONIZATION_PARTIAL : 'partial',
  STATUS_SYNCHRONIZATION_FAILED  : 'failed',

  //--Synchronization errors
  ERROR_INVALID_TOKEN                : 0xDC00,
  ERROR_DOSICASE_UNKNOWN             : 0xDC01,
  ERROR_DOSICASE_UNAVAILABLE         : 0xDC02,
  ERROR_DOSICASE_NOT_ACTIVATED       : 0xDC03,
  ERROR_DOSICASE_OUTDATED_ACTIVATION : 0xDC04,
  ERROR_VERSION_UNKNOWN              : 0xDC05,
  ERROR_VERSION_UNAVAILABLE          : 0xDC06,

  //--Partial synchronization causes
  CAUSE_OUDATED_MAINTENANCE_DUE_DATE : 0x1,

  //--Security questions
  SECURITY_BEST_FRIEND_TEEN       : 0x0,
  SECURITY_FAVORITE_CHILDREN_BOOK : 0x1,
  SECURITY_FAVORITE_SINGER        : 0x2,
  SECURITY_FIRST_CAR              : 0x3,
  SECURITY_FIRST_BOSS             : 0x4,
  SECURITY_FIRST_BOUGHT_ALBUM     : 0x5,
  SECURITY_FIRST_COOKED_FOOD      : 0x6,

  //--Equipments format
  EQUIPMENT_FORMAT_SAPHIDOSE : 0x0,
  EQUIPMENT_FORMAT_DOSICARD  : 0x1
};

Constants.getPartialSynchronizationCauseMessage = (code) => {
  switch(code) {
    case Constants.CAUSE_OUDATED_MAINTENANCE_DUE_DATE:
      return 'Contrat de maintenance expiré';
    default:
      return `CAUSE_${code}`;
  }
}

Constants.getErrorMessage = (code) => {

  switch(code) {
    case Constants.ERROR_INVALID_TOKEN:
      return `Jeton d'accès invalide`;
    case Constants.ERROR_DOSICASE_UNAVAILABLE:
      return 'Non disponible';
    case Constants.ERROR_DOSICASE_NOT_ACTIVATED:
      return 'Non activé';
    case Constants.ERROR_DOSICASE_OUTDATED_ACTIVATION:
      return 'Ancienne activation';
    case Constants.ERROR_VERSION_UNKNOWN:
      return 'Version inconnue';
    case Constants.ERROR_VERSION_UNAVAILABLE:
      return 'Version non disponible';
    default:
      return `ERROR_${code}`;
  }

};

Constants.getExpirationMessage = (days) => {

  if (days > 0) {
    let message;

    if (days === 1) {
      message = "demain";
    } else {
      message = `dans ${days} jours`;
    }

    return `Expire ${message}`;

  } else {

    let message;

    if (days === 0) {
      message = "aujourd'hui";
    } else if (days === -1) {
      message = "hier";
    } else {
      message = `${Math.abs(days)} jours`;
    }

    return `Expiré depuis ${message}`;
  }
};

Constants.getActivationCode = (code) => {
  return `${code.slice(0, 4)}-${code.slice(4, 8)}-${code.slice(8, 12)}`;
};

//--Version states
Constants.VERSION_STATES = [
  Constants.VERSION_STATE_UNAVAILABLE,
  Constants.VERSION_STATE_TESTING,
  Constants.VERSION_STATE_AVAILABLE
];

Constants.getVersionStateInfos = (state) => {

  switch(state) {
    case Constants.VERSION_STATE_UNAVAILABLE:
      return {
        color: 'red',
        label: 'Désactivé'
      };

    case Constants.VERSION_STATE_TESTING:
      return {
        color: 'yellow',
        label: 'En phase de test'
      };

    case Constants.VERSION_STATE_AVAILABLE:
      return {
        color: 'green',
        label: 'Disponible'
      };

    default:
      return {
        color: 'grey',
        label: 'Inconnu'
      };
  }
};

Constants.getDosicaseAddress = (id) => {
  return `dosicase${id.toString().padStart(4, '0')}@dosicase.com`;
}

//--Equipment formats

Constants.EQUIPMENT_FORMATS = [
  Constants.EQUIPMENT_FORMAT_SAPHIDOSE,
  Constants.EQUIPMENT_FORMAT_DOSICARD
];

Constants.getEquipmentFormat = (format) => {

  switch(format) {

    case Constants.EQUIPMENT_FORMAT_SAPHIDOSE:
      return 'Saphidose';

    case Constants.EQUIPMENT_FORMAT_DOSICARD:
      return 'Dosicard';

    default:
      return 'Format inconnu';
  }

}

//--Mails

const GLOBAL_VARIABLES = [
  {
    name: 'contact',
    description: 'Lien de contact'
  }
];

const MAIL_NEW_VERSION = {
  id: 'mail.new_version',
  name: 'Nouvelle version',
  variables: [
    ...GLOBAL_VARIABLES,
    {
      name: 'version',
      description: 'Numéro de la nouvelle version'
    }
  ]
};

const MAIL_NEW_VERSION_WITH_CODE = {
  id: 'mail.new_version_with_code',
  name: "Nouvelle version + code d'activation",
  variables: [
    ...GLOBAL_VARIABLES,
    {
      name: 'version',
      description: 'Numéro de la nouvelle version'
    },
    {
      name: 'activation_code',
      description: "Code d'activation de la valise"
    }
  ]
};

const MAIL_REQUEST_SYNC = {
  id: 'mail.request_sync',
  name: 'Synchronization requise',
  variables: [
    ...GLOBAL_VARIABLES,
    {
      name: 'dosicase',
      description: 'Numéro de produit de la valise'
    }
  ]
};

Constants.MAILS = [
  MAIL_NEW_VERSION,
  MAIL_NEW_VERSION_WITH_CODE,
  MAIL_REQUEST_SYNC
];

Constants.getMail = (id) => {

  return Constants.MAILS.find((mail) => {
    return mail.id === id;
  });
};

export default Object.seal(Constants);
