import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, TextArea } from 'components';

import { Form, Modal, Header, TransitionablePortal, Icon } from 'semantic-ui-react'

import { API } from 'services';
import { Typeof } from 'utils';
import { Env } from 'env';

class ModalLogbookEntry extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.isEdit = Typeof.object(props.entry);

    let state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      },

      icon: 'add',
      title: 'Nouvelle entrée',
      entry: {},
      action: API.post,
      route: `/dosicases/${this.props.dosicase.id}/logbook`
    };

    if(this.isEdit) {

      state = {
        ...state,
        icon: 'edit',
        title: 'Modification entrée',
        entry: this.props.entry,
        action: API.put,
        route: `/dosicases/logbook/${this.props.entry.id}`
      };
    }

    console.log(state);

    this.state = state;
  }

  handleSubmit(data, onSucess, onError) {

    if(this.isEdit) {
      data.id = this.state.entry.id
    };

    this.state.action(this.state.route, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    const { entry } = this.state;

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon={this.state.icon} content={this.state.title} color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions} sanitize>

              {/* Date */}
              <Form.Field>
                <label htmlFor="inputDate" className="required">Date</label>
                <Input type="datetime-local" id="inputDate" name="date" value={entry.date} />
              </Form.Field>

              {/* Subject */}
              <Form.Field>
                <label htmlFor="inputSubject" className="required">Sujet</label>
                <Input type="text" id="inputSubject" name="subject" value={entry.subject} />
              </Form.Field>

              {/* Text */}
              <Form.Field>
                <label htmlFor="inputText">Contenu</label>
                <TextArea id="inputText" name="text" value={entry.text} />
              </Form.Field>

              {/* Mantis */}
              <Form.Field>
                <label htmlFor="inputMantis">Numéro <a href={`${Env.MANTIS_URL}/set_project.php?project_id=${Env.MANTIS_PROJECT_ID}`} target="_blank">MantisBT <Icon name="bug" fitted/></a></label>
                <Input type="number" id="inputMantis" name="mantis" value={entry.mantis} />
              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalLogbookEntry.propTypes = {
  onClose: PropTypes.func.isRequired,
  entry: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    mantis: PropTypes.number
  })
};

export default ModalLogbookEntry;
