import React from 'react'
import PropTypes from 'prop-types'

import { Section, Properties, Blurred, Empty, Text } from 'components';
import { Button, Icon } from 'semantic-ui-react';

import { ModalEditDosicaseDevice } from 'modals';

import { Typeof } from 'utils';

class SectionDevice extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
  }

  toggleEditModal() {

    //Show modal
    this.setState({
      showEditModal: !this.state.showEditModal
    });
  }

  handleCloseEditModal(device) {

    if (Typeof.object(device)) {

      //Update locally
      this.props.dosicase.device = device;
    }

    //Hide modal
    this.toggleEditModal();
  }

  render() {

    const dosicase = this.props.dosicase;

    let deviceProperties = [
      {
        name: 'Modèle',
        value: Typeof.string(dosicase.device.model) ? <Text style="mono">{dosicase.device.model}</Text> : <Empty />
      },
      {
        name: 'Numéro de série',
        value: Typeof.string(dosicase.device.serial_number) ? <Text style="mono">{dosicase.device.serial_number}</Text> : <Empty />
      },
      {
        name: 'Gravure',
        value: Typeof.string(dosicase.device.engraving) ? <i>{dosicase.device.engraving}</i> : <Empty />
      },
      {
        name: 'Code de déverouillage',
        value: Typeof.string(dosicase.device.lock_pin) ? <Blurred value={dosicase.device.lock_pin} /> : <Empty />
      },
      {
        name: 'Mot de passe administrateur',
        value: Typeof.string(dosicase.device.admin_password) ? <Blurred value={dosicase.device.admin_password} /> : <Empty />
      }
    ];


    return (
      <Section title={<span><Icon name="tablet" />iPad</span>} actions={
        <Button icon="edit" color="yellow" circular onClick={this.toggleEditModal} />
      } collapsible collapsed>

        <Properties properties={deviceProperties} />

        {/* Dosicase device edit modal */}
        {this.state.showEditModal &&
          <ModalEditDosicaseDevice onClose={this.handleCloseEditModal} dosicase={dosicase} />
        }

      </Section>


    );
  }
}

export default SectionDevice;
