import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'

import moment from 'moment';

import { Container, Card, Label, Dropdown, Segment } from 'semantic-ui-react';

import { Section, Properties, Option, InfosBar, ButtonBar } from 'components';

import { BreadcrumbHelper, Typeof, Constants } from 'utils';
import { API } from 'services';

import './style.css';

class VersionScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: this.props.version.options,
      optionsDisplayMode: 0,
      optionsCriteria: '',
      pending: false
    };

    this.handleStateChange = this.handleStateChange.bind(this);

    this.handleOptionSearch = this.handleOptionSearch.bind(this);
    this.handleOptionDisplayChange = this.handleOptionDisplayChange.bind(this);

    this.handleOptionToggle = this.handleOptionToggle.bind(this);
    this.handleOptionValue = this.handleOptionValue.bind(this);
  }

  handleStateChange(event, { value }) {

    if(!this.state.pending) {
      this.setState({ pending: true }, () => {

        API.put(`/versions/${this.props.version.id}`, { state: value }).then(() => {

          //Update version state locally
          this.props.version.state = value;

        }).finally(() => {

          this.setState({ pending: false });
        })

      });
    }
  }

  handleOptionSearch(criteria) {
    this.setState({
      optionsCriteria: criteria
    });
  }

  handleOptionDisplayChange(current) {

    this.setState({
      optionsDisplayMode: current
    });
  }

  handleOptionToggle(option, checked, onComplete) {

    let request = checked ? API.post : API.delete;

    request(`/versions/${this.props.version.id}/options/${option.id}`)
      .then(() => {

        let selectedOptions = this.state.selectedOptions;

        if(checked) {

          //Associate
          selectedOptions.push({ option: option.id });
        } else {

          //Dissociate
          selectedOptions = selectedOptions.filter((optionLink) => {
            return optionLink.option !== option.id
          });
        }

        this.setState({ selectedOptions: selectedOptions });

      }).catch((error) => {
        //
      }).finally(onComplete);
  }

  handleOptionValue(option, value, onComplete) {

    API.put(`/versions/${this.props.version.id}/options/${option.id}`, { value: value }).then(() => {

      if(Typeof.defined(value)) {
        value = `${value}`;
      }

      let selectedOptions = this.state.selectedOptions.map((optionLink) => {

        //Update value overwrite
        if(option.id === optionLink.id) {
          optionLink.value_overwrite = value;
        }

        return optionLink;
      });

      this.setState({ selectedOptions: selectedOptions });

    }).finally(onComplete);
  }

  componentWillMount() {
    this.updateBreadcrumb();
  }

  updateBreadcrumb() {
    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Versions', '/versions');
    BreadcrumbHelper.push(`Version ${this.props.version.id}`);
    BreadcrumbHelper.flush();
  }

  render() {

    let version = this.props.version;

    let states = Constants.VERSION_STATES.map((state, index) => {

      let infos = Constants.getVersionStateInfos(state);

      return {
        key: index,
        value: state,
        text: (
          <span className="state">
            <Label circular empty color={infos.color} />
            {infos.label}
          </span>
        )
      }
    })

    let informations = [
      {
        name: 'Numéro de version',
        value: <b>{version.id}</b>
      },
      {
        name: 'Status',
        value: <Dropdown className="version-state" disabled={this.state.pending} onChange={this.handleStateChange} options={states} value={version.state} direction="left" />
      },
      {
        name: 'Date de création',
        value: moment(version.date_create).format('[Le] DD/MM/YYYY [à] HH:mm:ss')
      }
    ];

    let criteria = this.state.optionsCriteria;
    let hasFilter = criteria.length > 0;

    let options = this.props.options;

    //Filter options
    if(hasFilter) {
      options = options.filter((option) => {

        return option.id.includes(criteria)
            || option.name.toLowerCase().includes(criteria.toLowerCase())
            || option.description.toLowerCase().includes(criteria.toLowerCase());
      });
    }

    return (
      <React.Fragment>

        {/* Informations */}
        <Container>

          <Section title="Informations">
            <Properties properties={informations} />
          </Section>

        </Container>

        {/* Options */}
        <Container>

          <Section title="Sélection des options" onSearch={this.handleOptionSearch} actions={

            // Display mode bar
            <ButtonBar current={this.state.optionsDisplayMode} onChange={this.handleOptionDisplayChange} />
          }>

            {this.props.options.length > 0 ? (

              <React.Fragment>

                {/* Options informations */}
                <InfosBar>
                  <span>{this.props.options.length > 1 ? `${this.props.options.length} options disponibles` : '1 option disponible'}</span>
                  <span>{this.state.selectedOptions.length === 0 ? 'Aucune option sélectionnée' : this.state.selectedOptions.length > 1 ? `${this.state.selectedOptions.length} options sélectionnées` : '1 option sélectionnée'}</span>
                </InfosBar>

                {options.length > 0 ? (

                  // Options cards
                  <Card.Group itemsPerRow={this.state.optionsDisplayMode + 1}>
                    {options.map((option) => {

                      let optionLink = this.state.selectedOptions.find((optionLink) => optionLink.option === option.id);
                      let selected = Typeof.object(optionLink);
                      let valueOverwrite = selected ? optionLink.value_overwrite : undefined;

                      return <Option key={option.id} option={option} active={selected} value={valueOverwrite} toggle onToggle={this.handleOptionToggle} onValueChange={this.handleOptionValue} />
                    })}
                  </Card.Group>

                ) : (

                  // No options found for criteria
                  <Segment>
                    <InfosBar mode="row">
                      <span>Aucune option ne correspond à votre recherche</span>
                    </InfosBar>
                  </Segment>

                )}

              </React.Fragment>

            ) : (

              // No options available
              <InfosBar mode="row">
                <span>Aucune option n'est disponible</span>
              </InfosBar>

            )}

          </Section>

        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(VersionScene);
