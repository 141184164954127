import React from 'react'
import PropTypes from 'prop-types'

import { Table as SemanticTable, Pagination } from 'semantic-ui-react';

import classnames from 'classnames';

import { Typeof } from 'utils';

import './style.css';

class Table extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      selected: null,
      sort: {
        column: this.props.sort,
        direction: this.props.direction
      },
      pagination: {
        current: 1,
        total: 1
      }
    };

    //Initial state of items
    this.refresh(this.state, this.props.criteria, true);

    this.handleSort = this.handleSort.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);
  }

  handleSort(column) {

    let direction;

    if(this.state.sort.column !== column) {
      direction = 'ascending';
    } else {
      direction = this.state.sort.direction === 'ascending' ? 'descending' : 'ascending';
    }

    this.setState({
      sort: {
        column: column,
        direction: direction
      }
    });

  }

  handlePageChange(event, { activePage }) {

    this.setState({
      pagination: {
        current: activePage,
        total: this.state.pagination.total
      }
    });
  }

  handleRowSelect(item, index) {
    this.setState({
      selected: item
    });

    this.props.onSelect(item, index);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {

    //Check for items or criteria changes
    if(prevProps !== this.props || prevState !== this.state) {
      return true;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    //Refresh
    if(snapshot) {
      this.refresh(this.state, this.props.criteria, prevProps.criteria !== this.props.criteria);
      this.forceUpdate();
    }
  }

  refresh(state, criteria, forcePaginationUpdate) {

    let items = this.props.items;
    let columns = this.props.columns;

    //Filter
    if(Typeof.string(criteria) && criteria.length > 0) {

      items = items.filter((item) => {

        //Get all values of the item
        let values = [];

        columns.forEach((column) => {

          if(Typeof.func(column.value)) {

            let value = column.value(item);

            if(Typeof.defined(value)) {

              if(Typeof.array(value)) {

                value.forEach((_value) => {
                  values.push(`${_value}`.trim().toLowerCase());
                });

              } else {

                values.push(`${value}`.trim().toLowerCase());
              }
            }
          }

        });

        //Check if any of the value contains the search cirtera
        return values.some((value) => {
          return value.includes(criteria.toLowerCase());
        });
      });
    }

    //Sort
    if(state.sort.column !== null) {

      let column = this.props.columns.find((column) => column.id == state.sort.column);

      if(Typeof.func(column.value)) {

        items = items.sort((a, b) => {

          if(Typeof.func(column.sort)) {
            return column.sort(a, b)

          } else {

            let valueA = column.value(a);
            let valueB = column.value(b);

            if(Typeof.number(valueA) && Typeof.number(valueB)) {
              return valueA - valueB;
            }

            if(Typeof.undefined(valueA) || Typeof.null(valueA)) {
              return 1;
            } else if(Typeof.undefined(valueB) || Typeof.null(valueB)) {
              return -1;
            }

            return valueA.toString().localeCompare(valueB.toString());
          }
        });

        //Reverse when descending order
        if(state.sort.direction === 'descending') {
          items.reverse();
        }
      }
    }

    if(Typeof.number(this.props.itemsPerPage) && this.props.itemsPerPage > 0) {

      if (this.props.criteria !== criteria || forcePaginationUpdate) {
        state.pagination.current = 1;
        state.pagination.total = Math.ceil(items.length / this.props.itemsPerPage);
      }

      //Paginate
      let index = (state.pagination.current - 1) * this.props.itemsPerPage;
      state.items = items.slice(index, index + this.props.itemsPerPage);
    } else {
      state.items = items;
    }

  }

  render() {

    let columns = this.props.columns;

    let items = this.state.items;

    let hasPagination = Typeof.number(this.props.itemsPerPage) && this.props.itemsPerPage > 0;

    let selectable = Typeof.func(this.props.onSelect);

    //Render
    return (
      <SemanticTable color="yellow" sortable celled selectable={selectable}>

        <SemanticTable.Header>
          <SemanticTable.Row>

            {/* Headers */}
            {columns.map((column, index) => {

              let sortable = Typeof.string(column.id) && Typeof.func(column.value);
              let sorted = sortable && this.state.sort.column === column.id ? this.state.sort.direction : null;

              let classes = classnames({ 'fit': column.fit });

              return (
                <SemanticTable.HeaderCell key={index} sorted={sorted} onClick={sortable ? () => { this.handleSort(column.id) } : null} className={classes}>
                  {column.header}
                </SemanticTable.HeaderCell>
              );
            })}

          </SemanticTable.Row>
        </SemanticTable.Header>

        <SemanticTable.Body>

          {/* Rows */}
          {items.length > 0 ? (

            items.map((item, index) => {

              // Render cells
              let cells = columns.map((column) => {

                let value = undefined;

                if(Typeof.func(column.value)) {
                  value = column.value(item);
                }

                let content = null

                if(Typeof.func(column.render)) {
                  content = column.render(item, value);
                } else {
                  content = value
                }

                let classes = [];
                classes.push({'fit': column.fit});

                if(Typeof.func(column.classes)) {
                  classes.push(column.classes(item));
                }

                return (
                  <SemanticTable.Cell key={`${index}:${column.id}`} className={classes} selectable={column.selectable}>
                    {content}
                  </SemanticTable.Cell>
                )
              });

              // Render rows
              return (
                <SemanticTable.Row key={index} onClick={() => {
                  if(selectable) {
                    this.handleRowSelect(item, index);
                  }
                }} active={this.state.selected === item}>
                  {cells}
                </SemanticTable.Row>
              );
            })

          ) : (

            // No element
            <SemanticTable.Row disabled>
              <SemanticTable.Cell colSpan={columns.length} className="no-element">
                <span>{this.props.messageEmpty}</span>
              </SemanticTable.Cell>
            </SemanticTable.Row>
          )}

        </SemanticTable.Body>

        {/* Pagination */}
        {hasPagination && items.length > 0 &&
          <SemanticTable.Footer>
            <SemanticTable.Row>
              <SemanticTable.HeaderCell colSpan={columns.length}>
                <div className="table-pagination">
                  <Pagination activePage={this.state.pagination.current} totalPages={this.state.pagination.total} onPageChange={this.handlePageChange} />
                </div>
              </SemanticTable.HeaderCell>
            </SemanticTable.Row>
          </SemanticTable.Footer>
        }

      </SemanticTable>
    );
  }
}

Table.defaultProps = {
  sort: null,
  direction: null,
  messageEmpty: 'Aucun élément ne correspond à votre recherche'
};

Table.propTypes = {
  criteria: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    header: PropTypes.string.isRequired,
    value: PropTypes.func.isRequired,
    render: PropTypes.func,
    fit: PropTypes.bool,
    classes: PropTypes.func
  })),
  items: PropTypes.array.isRequired,
  messageEmpty: PropTypes.string.isRequired,
  onSelect: PropTypes.func
};

export default Table;
