let Values = {
  TEENAGER_BEST_FRIEND_NAME: 'TEENAGER_BEST_FRIEND_NAME',
  FIRST_PET_NAME: 'FIRST_PET_NAME',
  FIRST_DISH_LEARNED_TO_COOK: 'FIRST_DISH_LEARNED_TO_COOK',
  FIRST_MOVIE_SEEN_IN_THEATER: 'FIRST_MOVIE_SEEN_IN_THEATER',
  FIRT_FLEW_DESTINATION: 'FIRT_FLEW_DESTINATION',
  FAVORITE_ELEMENTARY_SCHOOL_TEACHER_NAME: 'FAVORITE_ELEMENTARY_SCHOOL_TEACHER_NAME'
};

export default Object.seal({
  ...Values,

  /* All values */
  values: Object.keys(Values),

  /* Get name for value. */
  name: (value) => {
    switch (value) {
      case Values.TEENAGER_BEST_FRIEND_NAME:
        return `Comment s'appelait votre meilleur ami lorsque vous étiez adolescent ?`;
      case Values.FIRST_PET_NAME:
        return `Comment s'appelait votre premier animal de compagnie ?`;
      case Values.FIRST_DISH_LEARNED_TO_COOK:
        return `Quel est le premier plat que vous avez appris à cuisiner ?`;
      case Values.FIRST_MOVIE_SEEN_IN_THEATER:
        return `Quel est le premier film que vous avez vu au cinéma ?`;
      case Values.FIRT_FLEW_DESTINATION:
        return `Où êtes-vous allé la première fois que vous avez pris l'avion ?`;
      case Values.FAVORITE_ELEMENTARY_SCHOOL_TEACHER_NAME:
        return `Comment s'appelait votre instituteur préféré à l'école primaire ?`;
    }
  }
});
