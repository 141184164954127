import React from 'react'
import PropTypes from 'prop-types';

import { FormWrapper, Input } from 'components';
import { TransitionablePortal, Modal, Header, Form } from 'semantic-ui-react';

import { API } from 'services';

import { Typeof } from 'utils';

class ModalDosicaseDocument extends React.Component {

  constructor(props) {
    super(props);

    this.fileInput = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.isEdit = Typeof.object(props.document);

    let state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: props.onClose
        }
      },

      icon: 'add',
      title: 'Nouveau document',
      document: {},
      method: API.post,
      route: `/dosicases/${props.dosicase.id}/documents`,
      name: null
    };

    if(this.isEdit) {

      state = {
        ...state,
        icon: 'edit',
        title: 'Modification document',
        document: props.document,
        method: API.put,
        route: `/dosicases/documents/${props.document.id}`
      };
    }

    this.state = state;
  }

  handleChange() {

    let file = this.fileInput.current.files[0];
    let name = null;

    if(Typeof.object(file)) {
      name = file.name;
    }

    this.setState({
      name: name
    });
  }

  handleSubmit(data, onSucess, onError) {

    let payload = null;

    if(!this.isEdit) {

      payload = new FormData();
      payload.append('file', this.fileInput.current.files[0]);

      if (data.name) {
        payload.append('name', data.name);
      }

    } else {

      payload = data;
    }

    this.state.method(this.state.route, payload)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {
    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon={this.state.icon} content={this.state.title} color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions}>

              {/* File */}
              {!this.isEdit &&
                <Form.Field>
                  <label htmlFor="inputContractFile" className="required">Fichier</label>
                  <input type="file" id="inputContractFile" name="file" ref={this.fileInput} onChange={this.handleChange} />
                </Form.Field>
              }

              {/* Name */}
              <Form.Field>
                <label htmlFor="inputName">Nom</label>
                <Input id="inputName" name="name" placeholder={this.state.name} value={this.state.document.name} />
              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalDosicaseDocument.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalDosicaseDocument;
