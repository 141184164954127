import { Auth } from 'services';
import { Button, Header, Modal, Form, Message } from 'semantic-ui-react'
import { Redirect } from "react-router-dom";

import React from 'react'

class LoginScene extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      redirectToReferrer: Auth.isLogged(),
      submit: false,
      error: false
    };
  }

  handleChange(event, change) {
    let state = this.state;
    state[change.id] = change.value;
    this.setState(state);
  }

  handleSubmit() {

    this.setState({
      submit: true,
      error: false
    });

    let { email, password } = this.state;

    Auth.login(email, password)
      .then(() => {
        this.setState({ redirectToReferrer: true });
      })
      .catch((error) => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ submit: false });
      });
  }

  render() {

    let { from } = this.props.location.state || { from: { pathname: "/" } };

    if(this.state.redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <Modal open size='small' dimmer="blurring">
        <Header icon='sign-in' content='Connexion' color="yellow"/>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>

            {/* Email */}
            <Form.Field>
              <label>Adresse e-mail</label>
              <Form.Input id="email" onChange={this.handleChange} placeholder='exemple@domain.com' />
            </Form.Field>

            {/* Password */}
            <Form.Field>
              <label>Mot de passe</label>
              <Form.Input id="password" onChange={this.handleChange} type="password" />
            </Form.Field>

            <Button type='submit' color="yellow" disabled={this.state.submit} loading={this.state.submit}>Se connecter</Button>
          </Form>

          {/* Show error */}
          {this.state.error &&
            <Message negative>
              <p>Identifiant et/ou mot de passe incorrect</p>
            </Message>
          }

        </Modal.Content>
      </Modal>
    );
  }
}

export default LoginScene;
