import React from 'react'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { CustomerScene } from 'scenes';

class CustomerContainer extends AbstractContainer {

  getTasks(params) {

    let id = parseInt(params.customer);

    return [
      {
        key: 'customer',
        exec: (done, failure) => {
          API.get(`customers/${id}`)
            .then((response) => {

              let customer = {
                ...response.data,
                id: id
              };

              done(customer);
            })
            .catch(failure);
        }
      },
      {
        key: 'dosicases',
        exec: (done, failure) => {
          API.get(`customers/${id}/dosicases`)
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <CustomerScene customer={results.customer} dosicases={results.dosicases} />
  }
}

export default CustomerContainer;
