import React from 'react'
import PropTypes from 'prop-types'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { DosicaseListScene } from 'scenes';

import { Typeof } from 'utils';

class DosicaseListContainer extends AbstractContainer {

  getTasks() {

    return [
      {
        key: 'customers',
        exec: (done, failure) => {
          API.get('customers')
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      },
      {
        key: 'dosicases',
        exec: (done, failure) => {
          API.get('dosicases')
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {

    let dosicases = results.dosicases.map((dosicase) => {

      if(Typeof.object(dosicase.customer)) {

        //Get customer of dosicase
        let customer = results.customers.find((customer) => customer.id === dosicase.customer.id);
        dosicase.customer = customer;
      }

      return dosicase;
    });

    return <DosicaseListScene dosicases={dosicases} />
  }
}

export default DosicaseListContainer;
