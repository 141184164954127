import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import { Section, Table, Country, InfosBar, ExternalLink, TextGroup, Text } from 'components';
import { Container, Button } from 'semantic-ui-react'

import { ModalCustomer } from 'modals';

import { BreadcrumbHelper, Typeof, Countries, Locales } from 'utils';

import './style.css';

const CUSTOMER_PER_PAGE = 10;

class CustomerListScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      customers: props.customers,
      customerCriteria: '',
      showCustomerCreateModal: false
    };

    this.handleCustomerSearch = this.handleCustomerSearch.bind(this);

    this.toggleCustomerCreateModal      = this.toggleCustomerCreateModal.bind(this);
    this.handleCustomerCreateModalClose = this.handleCustomerCreateModalClose.bind(this);

    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
  }

  componentWillMount() {

    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Clients');
    BreadcrumbHelper.flush();

    const params = new Map(this.props.location.search.slice(1).split('&').map(kv => kv.split('=')))

    if(params.has('s')) {
      this.setState({
        customerCriteria: params.get('s').trim()
      });
    }
  }

  handleCustomerSearch(criteria) {

    this.setState({
      customerCriteria: criteria
    });
  }

  toggleCustomerCreateModal() {
    this.setState({
      showCustomerCreateModal: !this.state.showCustomerCreateModal
    });
  }

  handleCustomerCreateModalClose(customer) {

    if(Typeof.object(customer)) {
      customer.dosicases = [];

      let customers = [...this.state.customers];
      customers.push(customer);

      this.setState({
        customers: customers
      });
    }

    this.toggleCustomerCreateModal();
  }

  handleCustomerSelect(customer) {

    //Navigate to customer page
    this.props.history.push(`/customers/${customer.id}`);
  }

  render() {

    const { customers } = this.state;

    let columns = [
      {
        id: 'company',
        header: 'Société',
        value: (customer) => customer.company,
        render: (customer, value) => <Text style="capitalize">{value}</Text>,
        fit: true
      },
      {
        id: 'identity',
        header: 'Identité',
        value: (customer) => [customer.lastname, customer.firstname],
        render: (customer, values) => (
          <TextGroup mode="lines">
            <Text style="uppercase">{values[0]}</Text>
            <Text style="capitalize">{values[1]}</Text>
          </TextGroup>
        )
      },
      {
        id: 'email',
        header: 'Adresse e-mail',
        value: (customer) => customer.email,
        render: (customer, value) => <ExternalLink target={`mailto:${value}`}>{value}</ExternalLink>
      },
      {
        id: 'phone_number',
        header: 'Numéro de tél.',
        value: (customer) => customer.phone,
        render: (customer, value) => <Text style="mono">{value}</Text>
      },
      {
        id: 'address',
        header: 'Adresse',
        value: (customer) => [customer.address, customer.zip_code, customer.city],
        render: (customer, values) => `${values[0]}, ${values[1]} ${values[2]}`
      },
      {
        id: 'country',
        header: 'Pays',
        value: (customer) => Countries.getCountry(customer.country).name,
        render: (customer, value) => <Country country={customer.country} />,
        fit: true
      },
      {
        id: 'locale',
        header: 'Langue',
        value: (customer) => Locales.getLocale(customer.locale).name,
        fit: true
      },
      {
        id: 'dosicases',
        header: 'Valise(s)',
        value: (customer) => customer.dosicases.length,
        fit: true
      },
    ];

    return (
      <React.Fragment>
        <Container>

          {/* Customers */}
          <Section title="Clients" onSearch={this.handleCustomerSearch} criteria={this.state.customerCriteria} actions={[

            // Add customer
            <Button icon="add" content="Nouveau" onClick={this.toggleCustomerCreateModal} color="yellow" key={0} />
          ]}>

            {customers.length > 0 ? (

              <React.Fragment>

                {/* Number of available customers */}
                <InfosBar mode="column">
                  <span>{customers.length > 1 ? `${customers.length} clients disponibles` : '1 client disponible'}</span>
                </InfosBar>

                {/* Customers list */}
                <Table columns={columns}
                  criteria={this.state.customerCriteria}
                  items={customers}
                  itemsPerPage={CUSTOMER_PER_PAGE}
                  onSelect={this.handleCustomerSelect}
                  messageEmpty="Aucun client ne correspond à votre recherche"
                />

              </React.Fragment>

            ) : (

              // No customer available
              <InfosBar mode="row">
                <span>Aucun client disponible</span>
              </InfosBar>

            )}

          </Section>

        </Container>

        {/* Modal create customer */}
        {this.state.showCustomerCreateModal &&
          <ModalCustomer onClose={this.handleCustomerCreateModalClose} />
        }

      </React.Fragment>
    );
  }
}

export default withRouter(CustomerListScene);
