import React from 'react'
import PropTypes from 'prop-types'

import compareVersions from 'compare-versions';

import { FormWrapper, Input } from 'components';

import { Form, Modal, Header, TransitionablePortal, Dropdown, Popup, Label } from 'semantic-ui-react'

import { API } from 'services';
import { Typeof, Constants } from 'utils';

import './style.css';

class ModalVersion extends React.Component {

  constructor(props) {
    super(props);

    this.handleFilterVersion = this.handleFilterVersion.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    let state = {
      versions: this.props.versions.sort((a, b) => compareVersions(a.id, b.id)).reverse(),
      versionToDuplicationOptionsFrom: null,
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      }
    }

    //Select latest version to duplicate from
    if(state.versions.length > 0) {
      state.versionToDuplicationOptionsFrom = state.versions[0].id
    }

    this.state = state;
  }

  handleFilterVersion(event, { value }) {

    this.setState({
      versionToDuplicationOptionsFrom: !!value ? value : null
    });
  }

  handleSubmit(data, onSucess, onError) {

    let route = '/versions';

    if(this.state.versionToDuplicationOptionsFrom != null) {
      route = `/versions/${this.state.versionToDuplicationOptionsFrom}/duplicate`;
    }

    API.post(route, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    let versionsFilter = this.state.versions.map((version, index) => {

      let infos = Constants.getVersionStateInfos(version.state);

      return {
        key: index,
        value: version.id,
        text: (
          <span className="version">

            {/* State */}
            <Popup
              trigger={<Label circular empty color={infos.color} />}
              content={infos.label}
              inverted
              position="bottom left"
            />

            {/* Version number */}
            {version.id}

          </span>
        )
      }
    });

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon="add" content="Nouvelle version" color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions}>

              {/* Number */}
              <Form.Field>
                <label htmlFor="inputName" className="required">Numéro de version</label>
                <Input id="inputName" name="id" placeholder="Ex: 1.2.3" />
              </Form.Field>

              <Form.Field>
                <lebel htmlFor="inputDuplicate">Dupliquer les options de la version</lebel>
                <Dropdown scrolling value={this.state.versionToDuplicationOptionsFrom} options={versionsFilter} onChange={this.handleFilterVersion} selection fluid clearable placeholder="Aucune version"/>
              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalVersion.propTypes = {
  onClose: PropTypes.func.isRequired,
  version: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired
  })
};

export default ModalVersion;
