import React from 'react'
import PropTypes from 'prop-types'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { VersionScene } from 'scenes';

class VersionContainer extends AbstractContainer {
  getTasks(params) {

    let id = params.version;

    return [
      {
        group: 1,
        key: 'options',
        exec: (done, failure) => {
          API.get('/options/active')
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      },
      {
        group: 1,
        key: 'version',
        exec: (done, failure) => {
          API.get(`/versions/${id}`)
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      },
      {
        group: 1,
        key: 'version_options',
        exec: (done, failure) => {
          API.get(`/versions/${id}/options`)
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {

    let version = {
      ...results.version,
      options: results.version_options
    };

    return <VersionScene version={version} options={results.options} />
  }
}

export default VersionContainer;
