import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'

import { Container, Tab } from 'semantic-ui-react';

import {
  PaneInformations,
  PaneContracts,
  PaneOptions,
  PaneActivity,
  PaneContacts,
  PaneDocuments,
  PaneLogbook
} from './panes';

import { Typeof } from 'utils';

import './style.css';

class DosicaseScene extends React.Component {

  render() {

    const { dosicase } = this.props;

    let hasCustomer = Typeof.object(dosicase.customer);

    let panes = [];

    //Pane informations
    panes.push({
      menuItem: {
        content: 'Informations',
        icon: 'info'
      },
      render: () => {
        return <PaneInformations dosicase={dosicase} hasCustomer={hasCustomer} />
      }
    });

    //Pane contracts
    panes.push({
      menuItem: {
        content: 'Contrats',
        icon: 'certificate'
      },
      render: () => {
        return <PaneContracts dosicase={dosicase} />
      }
    });

    //Pane documents
    panes.push({
      menuItem: {
        content: 'Documents',
        icon: 'folder open'
      },
      render: () => {
        return <PaneDocuments dosicase={dosicase} />
      }
    });

    //Pane options
    panes.push({
      menuItem: {
        content: 'Options',
        icon: 'options'
      },
      render: () => {
        return <PaneOptions dosicase={dosicase} versions={this.props.versions} options={this.props.options} />
      }
    });

    //Pane logbook
    panes.push({
      menuItem: {
        content: 'Journal de bord',
        icon: 'book'
      },
      render: () => {
        return <PaneLogbook dosicase={dosicase} />
      }
    });

    //Pane activity
    panes.push({
      menuItem: {
        content: 'Activité',
        icon: 'feed'
      },
      render: () => {
        return <PaneActivity dosicase={dosicase} />
      }
    });

    //Pane contacts
    panes.push({
      menuItem: {
        content: 'Contacts',
        icon: 'address book'
      },
      render: () => {
        return <PaneContacts dosicase={dosicase} />
      }
    });

    return (
      <React.Fragment>

        <Container>
          <Tab menu={{ secondary: false, pointing: true }} panes={panes} id="tabs" />
        </Container>

      </React.Fragment>
    );
  }
}

export default withRouter(DosicaseScene);
