import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input } from 'components';
import { Form, Modal, Header, TransitionablePortal } from 'semantic-ui-react'

import { API } from 'services';

class ModalCreateDosicase extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      }
    };
  }

  handleSubmit(data, onSucess, onError) {

    API.post('/dosicases', data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon="add" content="Nouvelle valise" color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions}>

              {/* Name */}
              <Form.Field>
                <label htmlFor="inputProduct" className="required">Numéro de produit</label>
                <Input id="inputProduct" name="id" />
              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalCreateDosicase.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalCreateDosicase;
