import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames';

import './style.css';

class TextGroup extends React.Component {

  render() {

    return (
      <div className={classnames("text-group", this.props.mode)}>
        {this.props.children}
      </div>
    );
  }
}

TextGroup.defaultProps = {
  mode: "inline"
};

TextGroup.propTypes = {
  mode: PropTypes.oneOf(["inline", "rows"])
}

export default TextGroup;
