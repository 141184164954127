import React from 'react'
import PropTypes from 'prop-types'

import { Popup, Label, Icon } from 'semantic-ui-react';

import { Constants } from 'utils';

import moment from 'moment';

import './style.css';

class ContractDate extends React.Component {

  render() {

    let contract = this.props.contract;

    let now = moment();
    let date = contract.due_date;

    let days = Math.ceil(moment.duration(moment(date).diff(now)).asDays());
    let valid = days > 0;

    return (
      <div className="contract">

        <Popup
          position="top right"
          content={Constants.getExpirationMessage(days)}
          inverted
          trigger={(
            <Label color={valid ? 'green' : 'red'} >
              <Icon name={`calendar ${valid ? 'check' : 'times'} outline`} />
            </Label>
          )}
        />

        {/* Due date */}
        <span className="date">
          {moment(date).format('DD/MM/YYYY')}
        </span>

      </div>
    )
  }
}

export default ContractDate;
