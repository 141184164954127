import React from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom';
import { PublicRoute } from 'routes';

import { UnauthorizedScene, DummyScene } from 'scenes';

import { Auth } from 'services';
import { Typeof } from 'utils';

class PrivateRoute extends PublicRoute {

  render() {

    //User not logged
    if(!Auth.isLogged()) {
      return <Redirect to={{
        pathname: "/login",
        state: { from: this.props.location }
      }} />
    }

    //Check role
    let user = Auth.getUser();

    let roles = this.props.roles;

    if(Typeof.string(roles)) {
      roles = [roles];
    }

    if(Typeof.array(roles)) {

      //Fix roles
      roles = roles.map((role) => role.trim());

      //Not granted
      if(!roles.includes(user.role) && user.role !== 'ROLE_ADMIN') {
        return (
          <DummyScene>
            <UnauthorizedScene />
          </DummyScene>
        );
      }

    }

    return super.render();
  }
}

PrivateRoute.propTypes = {
  role: PropTypes.arrayOf(PropTypes.string)
};

export default PrivateRoute;
