import React from 'react'

import moment from 'moment';

import { Link, withRouter } from 'react-router-dom';

import { Section, Table, Index, Country, InfosBar, ContractDate, FileActions, Empty, TextGroup, Text } from 'components';
import { Container, Button, Icon } from 'semantic-ui-react'

import { ModalCreateDosicase } from 'modals';

import { BreadcrumbHelper, Typeof, Countries, Locales } from 'utils';

const DOSICASE_PER_PAGE = 10;

class DosicaseListScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dosicases: props.dosicases,
      dosicaseCriteria: '',
      showDosicaseCreateModal: false
    };

    this.handleDosicaseSearch = this.handleDosicaseSearch.bind(this);

    this.toggleDosicaseCreateModal = this.toggleDosicaseCreateModal.bind(this);
    this.handleDosicaseCreateModalClose = this.handleDosicaseCreateModalClose.bind(this);

    this.handleDosicaseSelect = this.handleDosicaseSelect.bind(this);
  }

  componentWillMount() {

    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Valises');
    BreadcrumbHelper.flush();
  }

  handleDosicaseSearch(criteria) {

    this.setState({
      dosicaseCriteria: criteria
    });
  }

  toggleDosicaseCreateModal() {
    this.setState({
      showDosicaseCreateModal: !this.state.showDosicaseCreateModal
    });
  }

  handleDosicaseCreateModalClose(dosicase) {

    if (Typeof.object(dosicase)) {

      let dosicases = [...this.state.dosicases];
      dosicases.push(dosicase);

      this.setState({
        dosicases: dosicases
      });
    }

    this.toggleDosicaseCreateModal();
  }

  handleDosicaseSelect(dosicase) {

    //Navigate to dosicase page
    this.props.history.push(`/dosicases/${dosicase.id}`);
  }

  render() {

    const { dosicases } = this.state;

    let columns = [
      {
        id: 'id',
        header: '#',
        value: (dosicase) => dosicase.id,
        render: (dosicase, value) => <Index value={value} />,
        fit: true
      },
      {
        id: 'society',
        header: 'Société',
        value: (dosicase) => Typeof.object(dosicase.customer) ? dosicase.customer.company : null,
        render: (dosicase, value) => {
          return value != null ? value : <Empty />;
        }
      },
      {
        id: 'country',
        header: 'Pays',
        value: (dosicase) => Typeof.object(dosicase.customer) ? [Countries.getCountry(dosicase.customer.country).name, Locales.getLocale(dosicase.customer.locale).name] : undefined,
        render: (dosicase) => Typeof.object(dosicase.customer) ? <Country country={dosicase.customer.country} locale={dosicase.customer.locale} /> : <Empty />,
        fit: true
      },
      {
        id: 'version',
        header: 'Version',
        value: (dosicase) => [dosicase.version, dosicase.ios_version],
        render: (dosicase, versions) => {

          return (
            <TextGroup mode="rows">

              {/* App */}
              <Text style="mono">
                <Icon name="tablet" />

                {Typeof.string(versions[0]) ? (
                  <Link to={`/versions/${versions[0]}`} onClick={e => e.stopPropagation()}>{<Text style="mono">{versions[0]}</Text>}</Link>
                ) : (
                  <Empty />
                )}

              </Text>


              {/* iOS */}
              <Text style="mono">
                <Icon name="apple" />

                {Typeof.string(versions[1]) ? (
                  <Text style="mono">{versions[1]}</Text>
                ) : (
                  <Empty />
                )}

              </Text>

            </TextGroup>
          )
        },
        fit: true
      },
      {
        id: 'install_date',
        header: "Date d'installation",
        value: (dosicase) => Typeof.string(dosicase.install_date) ? moment(dosicase.install_date).format('DD/MM/YYYY HH:mm:ss') : undefined,
        render: (dosicase) => Typeof.string(dosicase.install_date) ? <Text style="mono">{moment(dosicase.install_date).format('[Le] DD/MM/YYYY [à] HH:mm:ss')}</Text> : <Empty />
      },
      {
        id: 'contract',
        header: 'Echéance contrat de maintenance',
        value: (dosicase) => Typeof.object(dosicase.contract) ? moment(dosicase.contract.due_date).format('DD/MM/YYYY') : undefined,
        render: (dosicase) => {

          if (Typeof.object(dosicase.contract)) {
            return (
              <div className="contract-infos">
                <ContractDate contract={dosicase.contract} />
                <FileActions endpoint={`dosicases/contracts/${dosicase.contract.id}`} />
              </div>
            );
          }

          return <Empty />;
        },
        sort: (a, b) => {

          let contractA = a.contract;
          let contractB = b.contract;

          let hasContractA = Typeof.object(contractA);
          let hasContractB = Typeof.object(contractB);

          if(!hasContractA && hasContractB) {
            return -1;
          }

          if(!hasContractB && hasContractA) {
            return 1;
          }

          if(!hasContractA && !hasContractB) {
            return 0;
          }

          return moment(contractA.due_date).diff(moment(contractB.due_date));
        }
      }
    ];

    return (
      <React.Fragment>
        <Container>

          {/* Dosicases */}
          <Section title="Valises" onSearch={this.handleDosicaseSearch} actions={[

            // Add dosicase
            <Button icon="add" content="Nouveau" onClick={this.toggleDosicaseCreateModal} color="yellow" key={0} />
          ]}>

            {dosicases.length > 0 ? (

              <React.Fragment>

                {/* Number of available dosicases */}
                <InfosBar mode="column">
                  <span>{dosicases.length > 1 ? `${dosicases.length} valises disponibles` : '1 valise disponible'}</span>
                </InfosBar>

                {/* dosicases list */}
                <Table columns={columns}
                  criteria={this.state.dosicaseCriteria}
                  items={dosicases}
                  itemsPerPage={DOSICASE_PER_PAGE}
                  onSelect={this.handleDosicaseSelect}
                  messageEmpty="Aucune valise ne correspond à votre recherche"
                />

              </React.Fragment>

            ) : (

                // No dosicase available
                <InfosBar mode="row">
                  <span>Aucune valise disponible</span>
                </InfosBar>

              )}

          </Section>

        </Container>

        {/* Modal create dosicase */}
        {this.state.showDosicaseCreateModal &&
          <ModalCreateDosicase onClose={this.handleDosicaseCreateModalClose} />
        }

      </React.Fragment>
    );
  }
}

export default withRouter(DosicaseListScene);
