import React from 'react'
import PropTypes from 'prop-types'

import { Section, TextGroup, Text, InfosBar, ExternalLink } from 'components';
import { Container, Card, Icon, Button, Divider } from 'semantic-ui-react';

import { ModalOptions, ModalDosicaseContact } from 'modals';

import { Typeof } from 'utils';
import { API } from 'services';

import './style.css';

class PaneContacts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      targetContact: null,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false
    };

    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.handleCloseCreateModal = this.handleCloseCreateModal.bind(this);

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);

    this.getContactCard = this.getContactCard.bind(this);
  }

  toggleCreateModal() {

    //Show modal
    this.setState({
      showCreateModal: !this.state.showCreateModal
    });
  }

  handleCloseCreateModal(contact) {

    if (Typeof.object(contact)) {

      contact.type = 1;

      //Update locally
      this.props.dosicase.contacts.push(contact);
      this.forceUpdate();
    }

    //Hide modal
    this.toggleCreateModal();
  }

  toggleEditModal(contact) {

    //Show modal
    this.setState({
      targetContact: contact,
      showEditModal: !this.state.showEditModal
    });
  }

  handleCloseEditModal(update) {

    if(Typeof.object(update)) {

      //Update locally
      this.props.dosicase.contacts = this.props.dosicase.contacts.map((contact) => {

        if(contact.id === update.id) {
          contact.firstname = update.firstname;
          contact.lastname = update.lastname;
          contact.email = update.email;
          contact.phone = update.phone;
        }

        return contact;
      });
      this.forceUpdate();
    }

    //Hide modal
    this.toggleEditModal();
  }

  toggleDeleteModal(contact = null) {

    //Show modal
    this.setState({
      targetContact: contact,
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  handleCloseDeleteModal(result, setProcessing) {

    if (result !== ModalOptions.OPTION_YES) {
      this.toggleDeleteModal();
    } else {

      setProcessing(true);

      API.delete(`/dosicases/contacts/${this.state.targetContact.id}`)
        .then(() => {

          //Remove locally
          this.props.dosicase.contacts = this.props.dosicase.contacts.filter((contact) => contact.id !== this.state.targetContact.id);
          this.forceUpdate();

          this.toggleDeleteModal();
        });
    }
  }

  getContactCard(contact, editable) {

    return (
      <Card fluid color="yellow" className="contact-card" key={contact.id}>
        <Card.Content>
          <Card.Header>
            <InfosBar>

              {/* Identity */}
              <TextGroup>
                <Text style="uppercase">{contact.lastname}</Text>
                <Text style="capitalize">{contact.firstname}</Text>
              </TextGroup>

              {/* Actions */}
              {editable &&
                <div className="contact-actions">
                  <Icon link name="edit" onClick={() => this.toggleEditModal(contact)} />
                  <Icon link name="remove" color="red" onClick={() => this.toggleDeleteModal(contact) }/>
                </div>
              }

            </InfosBar>
          </Card.Header>
          <Card.Meta>
            {editable ? 'Secondaire' : 'Principal'}
          </Card.Meta>
          <Card.Description>
            <InfosBar>

              {/* Mail */}
              <div>
                <Icon name="mail" />
                <ExternalLink target={`mailto:${contact.email}`}>{contact.email}</ExternalLink>
              </div>

              {/* Phone */}
              {contact.phone &&
                <div>
                  <Icon name="phone" />
                  <Text style="mono">{contact.phone}</Text>
                </div>
              }

            </InfosBar>
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }

  render() {

    const { dosicase } = this.props;

    let contacts = dosicase.contacts;

    let main = contacts.find((contact) => contact.type === 0);
    let secondaries = contacts.filter((contact) => contact.type === 1);

    return (
      <React.Fragment>

        <Container>
          <Section title="Contacts" actions={
            <Button icon="add" color="yellow" onClick={this.toggleCreateModal} content="Nouveau" />
          }>

            {/* Contact informations */}
            <InfosBar mode="column">
              <span>{contacts.length === 0 ? 'Aucune contact' : contacts.length > 1 ? `${contacts.length} contacts` : '1 contact'}</span>
            </InfosBar>

            {/* Main contact */}
            {Typeof.object(main) ? (
              this.getContactCard(main, false)
            ) : (
              <InfosBar mode="row">
                <span>Aucun contact principal</span>
              </InfosBar>
            )}

            <Divider />

            {/* Secondaries contact */}
            {secondaries.length > 0 ? (

              secondaries.map((contact) => {
                return this.getContactCard(contact, true);
              })

            ) : (
              <InfosBar mode="row">
                <span>Aucun contact secondaire</span>
              </InfosBar>
            )}

          </Section>
        </Container>

        {/* Contact create modal */}
        {this.state.showCreateModal &&
          <ModalDosicaseContact dosicase={this.props.dosicase} onClose={this.handleCloseCreateModal} />
        }

        {/* Contact edit modal */}
        {this.state.showEditModal &&
          <ModalDosicaseContact dosicase={this.props.dosicase} contact={this.state.targetContact} onClose={this.handleCloseEditModal} />
        }

        {/* Contact delete warning modal */}
        {this.state.showDeleteModal &&
          <ModalOptions onClose={this.handleCloseDeleteModal} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de supprimer ce contact.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

      </React.Fragment>
    );
  }
}

export default PaneContacts;
