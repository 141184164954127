import { NavigationBar } from 'components';
import React from 'react';

/**
 * Dummy scene that render header without interactions
 */
class DummnyScene extends React.Component {

  render() {

    return (
      <React.Fragment>

        {/* Content */}
        <div id="content">
          {this.props.children}
        </div>

        {/* Navigation */}
        <NavigationBar />

      </React.Fragment>
    )
  }
}

export default DummnyScene;
