import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input } from 'components';
import { Form, Modal, Header, TransitionablePortal } from 'semantic-ui-react'

import { API } from 'services';

import { Typeof } from 'utils';

class ModalEditDosicaseDevice extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      }
    };
  }

  handleSubmit(data, onSucess, onError) {

    API.put(`/dosicases/${this.props.dosicase.id}/device`, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    let device = this.props.dosicase.device;

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size="small">
          <Header icon="edit" content="Modification valise – iPad" color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions} sanitize>

              {/* Model */}
              <Form.Field>
                <label htmlFor="inputDeviceModel">Modèle</label>
                <Input id="inputDeviceModel" name="model" value={device.model} />
              </Form.Field>

              {/* Serial number */}
              <Form.Field>
                <label htmlFor="inputDeviceSerialNumber">Numéro de série</label>
                <Input id="inputDeviceSerialNumber" name="serial_number" value={device.serial_number} />
              </Form.Field>

              {/* Engraving */}
              <Form.Field>
                <label htmlFor="inputDeviceEngraving">Gravure</label>
                <Input id="inputDeviceEngraving" name="engraving" value={device.engraving} />
              </Form.Field>

              {/* Lock pin */}
              <Form.Field>
                <label htmlFor="inputDeviceLockPin">Code de déverouillage</label>
                <Input id="inputDeviceLockPin" name="lock_pin" value={device.lock_pin} />
              </Form.Field>

              {/* Admin password */}
              <Form.Field>
                <label htmlFor="inputDeviceLockPin">Mot de passe administrateur</label>
                <Input id="inputDeviceLockPin" name="admin_password" value={device.admin_password} />
              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalEditDosicaseDevice.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalEditDosicaseDevice;
