import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Selection } from 'components';

import { Form, Modal, Header, TransitionablePortal } from 'semantic-ui-react'

import { API } from 'services';

class ModalLinkDosicase extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      dosicases: [],
      fetching: false,
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit,
          active: false
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      }
    };
  }

  componentDidMount() {

    //Request fetch
    this.setState({
      dosicases: [],
      fetching: true
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if(!prevState.fetching && this.state.fetching) {

      //Load available dosicases
      API.get('/dosicases', { filter: 'available' })
        .then((response) => {

          this.setState({
            dosicases: response.data
          });
        })
        .finally(() => {
          this.setState({
            fetching: false
          });
        });

    }
  }

  handleChange(event) {
    this.setState({
      actions: {
        ...this.state.actions,
        submit: {
          ...this.state.actions.submit,
          active: true
        }
      }
    });
  }

  handleSubmit(data, onSucess, onError) {

    API.put(`/customers/${this.props.customer.id}/dosicases/${data.dosicase}`)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError({'dosicase': [error.response.data.message]});
     });
  }

  render() {

    let availableDosicases = this.state.dosicases.map((dosicase) => {
      return {
        key: dosicase.id,
        text: dosicase.id,
        value: dosicase.id
      };
    });

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size="small">
          <Header icon="add" content="Associer une valise"color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions}>

              {/* Name */}
              <Form.Field>
                <label htmlFor="inputProduct">Numéro de produit</label>
                <Selection
                  placeholder={this.state.fetching ? 'Chargement' : null}
                  loading={this.state.fetching}
                  id="inputProduct"
                  name="dosicase"
                  options={availableDosicases}
                  noResultsMessage="Aucune valise n'est disponible"
                  onChange={this.handleChange}
                />
              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalLinkDosicase.propTypes = {
  onClose: PropTypes.func.isRequired,
  dosicase: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
};

export default ModalLinkDosicase;
