import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'semantic-ui-react';

import './style.css';

class Blurred extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      blurred: props.blurred
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      blurred: !this.state.blurred
    });
  }

  render() {
    return (
      <div>

        {!this.state.blurred &&
          <span>
            {this.props.value}
          </span>
        }

        <Icon className="blurred-eye" name={this.state.blurred ? 'eye slash' : 'eye'} onClick={this.handleClick} />
      </div>
    );
  }
}

Blurred.defaultProps = {
  blurred: true
};

Blurred.propTypes = {
  blurred: PropTypes.bool.isRequired
};

export default Blurred;
