import React from 'react'
import PropTypes from 'prop-types';

import { FormWrapper, Input } from 'components';
import { TransitionablePortal, Modal, Header, Form } from 'semantic-ui-react';

import { API } from 'services';

class ModalCreateDosicaseContract extends React.Component {

  constructor(props) {
    super(props);

    this.pdfInput = React.createRef();

    this.state = {
      selected: null,
      contractFile: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      }
    };
  }

  handleSubmit(data, onSucess, onError) {

    let payload = new FormData();
    payload.append('pdf', this.pdfInput.current.files[0]);

    if(data.due_date) {
      payload.append('due_date', data.due_date);
    }

    API.post(`dosicases/${this.props.dosicase.id}/contracts`, payload)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {
    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size='small'>
          <Header icon="upload" content="Nouveau contrat" color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions}>

              {/* Contract file */}
              <Form.Field>
                <label htmlFor="inputContractFile" className="required">Contrat</label>
                <input type="file" id="inputContractFile" name="pdf" ref={this.pdfInput} accept="application/pdf"/>
              </Form.Field>

              {/* Due date */}
              <Form.Field>
                <label htmlFor="inputDueDate" className="required">Echéance</label>
                <Input type="date" id="inputDueDate" name="due_date" />
              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalCreateDosicaseContract.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalCreateDosicaseContract;
