import React from 'react'
import PropTypes from 'prop-types'

import { Typeof } from 'utils';

class ExternalLink extends React.Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {

    //Stop event propagation
    event.stopPropagation();

    let onClick = this.props.onClick;

    if(Typeof.func(onClick)) {
      onClick();
    }
  }

  render() {
    return (
      <a href={this.props.target} onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

ExternalLink.propTypes = {
  target: PropTypes.string,
  onClick: PropTypes.func
};

export default ExternalLink;
