import React from 'react';

import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

import { NavigationBar } from 'components';
import { Sidebar, Image, List, Popup, Menu, Icon } from 'semantic-ui-react'

import { Auth } from 'services';
import { Env } from 'env';

import { Typeof } from 'utils';

import classnames from 'classnames';

import logo from 'assets/dosicase_logo.png';

import './scene.css';

class GenericScene extends React.Component {

  constructor(props) {
    super(props);

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleSidebarHide = this.handleSidebarHide.bind(this);

    this.state = {
      showMenu: true
    };
  }

  toggleSidebar() {

    this.setState({
      showMenu: !this.state.showMenu
    });
  }

  handleSidebarHide() {

    this.setState({
      showMenu: false
    });
  }

  render() {

    const isLogged = Auth.isLogged();

    let routes = [
      { /* Dosicases */
        path: '/dosicases',
        name: 'Valises',
        active: isLogged,
        roles: Auth.ROLE_API
      },
      { /* Versions */
        path: '/versions',
        name: 'Versions',
        active: isLogged,
        roles: Auth.ROLE_API
      },
      { /* Options */
        path: '/options',
        name: 'Options',
        active: isLogged,
        roles: Auth.ROLE_API
      },
      { /* Customers */
        path: '/customers',
        name: 'Clients',
        active: isLogged,
        roles: Auth.ROLE_API
      },
      { /* Customers */
        path: '/mails',
        name: 'E-mails',
        active: isLogged,
        roles: Auth.ROLE_API
      },
      { /* Debug log */
        path: '/logs',
        name: 'Journal de débogage',
        active: isLogged,
        roles: Auth.ROLE_ADMIN,
        icon: 'bug'
      }
    ];

    return (

      <React.Fragment>

        <Sidebar.Pushable className="sidebar-container">

          <Sidebar animation='overlay' visible={this.state.showMenu}>

            {/* Footer */}
            <div id="header">
              <Image src={logo} />
              Gestion et administration - v

                {/* Build informations */}
              <Popup
                trigger={<span className="version">{Env.BUILD_VERSION}</span>} on="hover" position='bottom center'
                content={
                  <List>
                    <List.Item>
                      <List.Icon name='calendar outline' />
                      <List.Content>{Env.BUILD_DATE}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name='code branch' />
                      <List.Content>{Env.BUILD_HASH}</List.Content>
                    </List.Item>
                  </List>
                }
              />
            </div>

            {/* Items */}
            <div id="items">

              {/* User */}
              {/* {Auth.isLogged() &&
                <Card fluid color="yellow">
                  <Card.Content>
                    <Card.Header>
                      <Icon name='user' />
                      {Auth.getUser().username}
                    </Card.Header>
                    <Card.Meta>{Auth.getUser().email}</Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <Icon name='sign-out' />
                    <Link to="/logout">Déconnexion</Link>
                  </Card.Content>
                </Card>
              } */}

              {/* Navigarion */}
              <Menu fluid vertical pointing secondary>
                {routes.map((route, index) => {

                  if(route.active && (Typeof.undefined(route.roles) || Auth.hasRole(route.roles))) {

                    let isCurrent = this.props.match.url.includes(route.path);

                    return (
                      <Link key={index} to={route.path}>
                        <Menu.Item active={isCurrent}>

                          {/* Icon */}
                          {Typeof.string(route.icon) &&
                            <Icon name={route.icon} />
                          }

                          {/* Name */}
                          {route.name}
                        </Menu.Item>
                      </Link>
                    );
                  }

                  return null;
                })}
              </Menu>
            </div>

          </Sidebar>

          <Sidebar.Pusher>

            {/* Content */}
            <div id="content" className={classnames({ 'show-navigation': this.props.showNavigation })}>
              {this.props.children}
            </div>

          </Sidebar.Pusher>

        </Sidebar.Pushable>

        {/* Navigation bar */}
        <NavigationBar onMenuToggle={this.toggleSidebar} />

      </React.Fragment>
    );
  }
}

export default withRouter(GenericScene);
