import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Popup } from 'semantic-ui-react'
import { Breadcrumb } from 'components';
import { Link } from 'react-router-dom';

import { Auth } from 'services';

import moment from 'moment';

import './style.css';

class NavigationBar extends React.Component {

  render() {

    let isAuth = Auth.isLogged();

    let account = null;

    if(isAuth) {

      let user = Auth.getUser();

      account = (
        <Popup flowing hoverable position="bottom right" trigger={
          <div id="account">
            <div className="infos">
              <span className="username">{user.username}</span>
              <span className="email">{user.email}</span>
            </div>
            <Icon name="user" inverted size="large" />
          </div>
        }>
          <div id="accountPopup">
            <span className="logged-at">{moment(user.logged_at).format('[Connecté le] DD/MM/YYYY [à] HH:mm:ss')}</span>
            <span>
              <Icon name="logout" />
              <Link to="/logout">Déconnexion</Link>
            </span>
          </div>
        </Popup>
      );
    }

    return (
      <div id="navigationBar">

        {/* Breadcrumb */}
        <div>
          <Icon name="bars" color="yellow" size="large" className="menu-toggle" onClick={this.props.onMenuToggle} />

          <Breadcrumb />
        </div>

        {/* Account */}
        {account}

      </div>
    );
  }
}

NavigationBar.propTypes = {
  onMenuToggle: PropTypes.func.isRequired
};

export default NavigationBar;
