import React from 'react';
import PropTypes from 'prop-types'

import { Image, Progress, Transition } from 'semantic-ui-react'

import logo from 'assets/dosicase_logo.png';

import './splash.css';

class Splash extends React.Component {

  render() {

    return (
      <Transition visible={this.props.visible} animation='fade' duration={{hide: 500}}>

        <div className="splash-container">

          <div className="splash">

            {/* DosiCase logo */}
            <Image src={logo} />

            <span>Gestion et administration</span>

          </div>

          <div className="loading-bar">

            {/* Loading bar */}
            <Progress percent={100} active className="loading-bar" color="yellow">
              {this.props.message}
            </Progress>
          </div>

        </div>

      </Transition>
    )
  }
}

Splash.propTypes = {
  visible: PropTypes.bool.isRequired,
  message: PropTypes.node
};

export default Splash;
