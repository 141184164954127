import React from 'react'
import PropTypes from 'prop-types'

import { Breadcrumb, Icon, Popup } from 'semantic-ui-react';

import { API } from 'services';

class FileActions extends React.Component {

  constructor(props) {
    super(props);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleOpen() {
    API.open(this.props.endpoint);
  }

  handleDownload() {
    window.open(API.url(this.props.endpoint, true));
  }

  render() {

    let { actions } = this.props;

    return (
      <Breadcrumb className="link">

        {/* Actions */}
        {actions.map((action, index) => {

          let handle = (event) => {
            event.stopPropagation();

            action.handle(this);
          };

          let content = (
            <Breadcrumb.Section onClick={handle} className={action.className}>

              {/* Icon */}
              {action.icon &&
                <Icon name={action.icon} />
              }

              {/* Content */}
              {action.content}

            </Breadcrumb.Section>
          );

          return (
            <React.Fragment>

              {action.popup ? (
                <Popup content={action.popup} trigger={content} position='bottom right' wide />
              ) : (
                content
              )}

              {/* Divider */}
              {index < (actions.length - 1) &&
                <Breadcrumb.Divider />
              }


            </React.Fragment>
          );

        })}
      </Breadcrumb>
    );

  }
}

FileActions.ACTION_OPEN = {
  icon: 'eye',
  content: 'Ouvrir',
  // popup: 'Ouvre le fichier dans un nouvel onglet du navigateur',
  handle: (component) => {
    component.handleOpen();
  }
};

FileActions.ACTION_DOWNLOAD = {
  icon: 'download',
  content: 'Télécharger',
  // popup: 'Enregistre le fichier sur l\'ordinateur',
  handle: (component) => {
    component.handleDownload();
  }
};

FileActions.DEFAULT_ACTIONS = [
  FileActions.ACTION_OPEN,
  FileActions.ACTION_DOWNLOAD
];

FileActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    content: PropTypes.string,
    popup: PropTypes.string,
    handle: PropTypes.func,
    className: PropTypes.string
  })),
  endpoint: PropTypes.string.isRequired
};

FileActions.defaultProps = {
  actions: FileActions.DEFAULT_ACTIONS
};

export default FileActions;
