import React from 'react'
import PropTypes from 'prop-types';

import { Route } from "react-router-dom";

import { GenericScene } from 'scenes';

class PublicRoute extends React.Component {

  render() {
    //Extract component and the rest of props
    const { component: Content, dummy, ...rest } = this.props;

    return (
      <Route {...rest} render={(props) =>

         /* Render generic scene with sub content */
        <GenericScene>
          <Content {...props} />
        </GenericScene>
      }/>
    )
  }
}

PublicRoute.propTypes = {
  component: PropTypes.element.isRequired
};

export default PublicRoute;
