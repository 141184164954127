import React from 'react'
import PropTypes from 'prop-types';

import { Route } from "react-router-dom";

import { DummyScene } from 'scenes';

class DummyRoute extends React.Component {

  render() {

    //Extract component and the rest of props
    const { component: Content, ...rest } = this.props;

    return (
      <Route {...rest} render={(props) =>

        <DummyScene>
          <Content {...props} />
        </DummyScene>
      } />
    )
  }
}

DummyRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default DummyRoute;
