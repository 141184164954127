let Countries = {
  FRANCE: {
    code: 'FR',
    name: 'France'
  },
  BELGIUM: {
    code: 'BE',
    name: 'Belgique'
  },
  SPAIN: {
    code: 'ES',
    name: 'Espagne'
  },
  CHINA: {
    code: 'CN',
    name: 'Chine'
  },
  UNITED_ARAB_EMIRATES: {
    code: 'AE',
    name: 'Emirats Arabes Unis'
  }
};

Countries.ALL = [
  Countries.FRANCE,
  Countries.BELGIUM,
  Countries.SPAIN,
  Countries.CHINA,
  Countries.UNITED_ARAB_EMIRATES
];

Countries.getCountry = (code) => {
  return Countries.ALL.find((country) => {
    return country.code === code;
  })
};

export default Object.seal(Countries);
