import React from 'react'
import PropTypes from 'prop-types'

import { Header as SemanticHeader, Input, Icon } from 'semantic-ui-react';

import { debounce } from 'lodash';

import { Typeof } from 'utils';

import classnames from 'classnames';

import './style.css';

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      criteria: props.criteria
    };

    this.handleType = this.handleType.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleType(event, { value }) {
    this.setState({
      criteria: value
    });

    this.handleSearch(value);
  }

  handleSearch = debounce((criteria) => {
    this.props.onSearch(criteria);
  }, 250);

  render() {

    let compact = this.props.compact;
    let searchable = Typeof.func(this.props.onSearch);
    let clickable = Typeof.func(this.props.onClick);

    return (
      <div className={classnames("titled-header", { "compact": compact, "clickable": clickable, "disabled": this.props.disabled })}>

        {/* Header */}
        <SemanticHeader dividing={!compact} onClick={this.props.onClick}>

          {/* Icon */}
          {this.props.icon &&
            <Icon name={this.props.icon} />
          }

          {this.props.header}
        </SemanticHeader>


        <div className="actions">

          {/* Searchbar */}
          {searchable &&
            <Input className="search-input" icon="search" onChange={this.handleType} placeholder={this.props.placeholder} value={this.state.criteria} />
          }

          {/* Actions */}
          {this.props.children}
        </div>

      </div>
    );
  }
}

Header.defaultProps = {
  placeholder: 'Rechercher...',
  compact: false,
  disabled: false
};

Header.propTypes = {
  header: PropTypes.node,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  compact: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired
};

export default Header;
