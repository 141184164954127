import React from 'react'
import PropTypes from 'prop-types'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { MailScene } from 'scenes';

class MailContainer extends AbstractContainer {

  getTasks() {

    return [
      {
        key: 'mails',
        exec: (done, failure) => {
          API.get('mails')
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <MailScene mails={results.mails} />
  }
}

export default MailContainer;
