import React from 'react'
import PropTypes from 'prop-types'

import { Header } from 'components';
import { Segment, Accordion } from 'semantic-ui-react';

import classnames from 'classnames';

import './style.css';

class Section extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.collapsible && props.collapsed
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if(this.props.collapsible) {

      this.setState({
        collapsed: !this.state.collapsed
      });

    }
  }

  render() {

    let collapsible = this.props.collapsible;
    let show = !collapsible || !this.state.collapsed;

    return (
      <Accordion className="section">

        <Segment>

          {/* Header */}
          <Accordion.Title active={show} index={0} className={classnames({ "collapsible": collapsible })}>

            <Header
              header={this.props.title}
              compact={!show}
              icon={collapsible ? "dropdown" : undefined}
              onSearch={this.props.onSearch}
              criteria={this.props.criteria}
              onClick={collapsible ? this.handleClick : undefined}
              disabled={(collapsible && !show) || this.props.disabled}
              >

              {this.props.actions}
            </Header>

          </Accordion.Title>

          {/* Content */}
          <Accordion.Content active={show}>
            {this.props.children}
          </Accordion.Content>

        </Segment>

      </Accordion>
    );
  }
}

Section.defaultProps = {
  collapsible: false,
  collapsed: false
};

Section.propTypes = {
  collapsible: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired
};

export default Section;
