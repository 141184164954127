import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, TextArea, InfosBar } from 'components';

import { Form, Modal, Header, TransitionablePortal, Divider, List, Icon } from 'semantic-ui-react'

import { API } from 'services';
import { Constants, Typeof } from 'utils';

class ModalEditMail extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      }
    };
  }

  handleSubmit(data, onSucess, onError) {

    API.put(`/mails/${this.props.mail}/locale/${this.props.locale.code}`, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  render() {

    let id = this.props.mail;
    let locale = this.props.locale;

    let mail = Constants.getMail(id);
    let data = this.props.data;

    let subject = Typeof.object(data) && Typeof.string(data.subject) && data.subject.trim().length > 0 ? data.subject.trim() : undefined;
    let content = Typeof.object(data) && Typeof.string(data.content) && data.content.trim().length > 0 ? data.content.trim() : undefined;

    let variables = mail.variables;

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size="large">
          <Header icon="edit" content={`${mail.name} (${locale.name})`} color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions}>

              {/* Subject */}
              <Form.Field>
                <label htmlFor="inputSubject">Objet</label>
                <Input id="inputSubject" name="subject" value={subject} style={{ direction: locale.direction }} />
              </Form.Field>

              {/* Content */}
              <Form.Field>
                <label htmlFor="inputContent">Contenu</label>
                <TextArea id="inputContent" name="content" value={content} style={{ direction: locale.direction }} />
              </Form.Field>

              {/* Variables */}
              <Divider horizontal>
                <Header as='h4'>
                  <Icon name='lab' />
                  Variables
                </Header>
              </Divider>

              {variables != null && variables.length > 0 ? (

                <List bulleted>

                  {variables.map((variable) => {

                    return (
                      <List.Item>
                        <List.Content>
                          <List.Header>{`{{ ${variable.name} }}`}</List.Header>
                          <List.Description>{variable.description}</List.Description>
                        </List.Content>
                      </List.Item>
                    )
                  })}

                </List>

              ) : (
                <InfosBar>
                  <span>Aucune variable disponible</span>
                </InfosBar>
              )}

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalEditMail.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalEditMail;
