let Locales = {

  ENGLISH: {
    code: 'en_EN',
    flag: 'GB',
    name: 'Anglais',
    direction: 'ltr'
  },

  FRENCH: {
    code: 'fr_FR',
    flag: 'FR',
    name: 'Français',
    direction: 'ltr'
  },

  SPANISH: {
    code: 'es_ES',
    flag: 'ES',
    name: 'Espagnol',
    direction: 'ltr'
  },

  FLEMISH: {
    code: 'nl_BE',
    flag: 'BE',
    name: 'Flamand (Belge)',
    direction: 'ltr'
  },

  ARABIC: {
    code: 'ar_UE',
    flag: 'AE',
    name: 'Arabe',
    direction: 'rtl'
  },

  CHINESE: {
    code: 'zn_CN',
    flag: 'CN',
    name: 'Chinois (simplifié)',
    direction: 'ltr'
  },
};

Locales.ALL = [
  Locales.ENGLISH,
  Locales.FRENCH,
  Locales.SPANISH,
  Locales.FLEMISH,
  Locales.ARABIC,
  Locales.CHINESE
];

Locales.DEFAULT = Locales.ENGLISH;

Locales.getLocale = (code) => {
  return Locales.ALL.find((locale) => {
    return locale.code === code;
  });
};

export default Object.seal(Locales);
