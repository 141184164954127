import React from 'react'
import PropTypes from 'prop-types'

import { withRouter, Link } from 'react-router-dom'

import { Container, Button } from 'semantic-ui-react';

import moment from 'moment';

import { Section, Properties, Country, Table, Index, InfosBar, ContractDate, FileActions, Empty, TextGroup, Text, ExternalLink } from 'components';
import { ModalOptions, ModalCustomer, ModalLinkDosicase } from 'modals';

import { BreadcrumbHelper, Typeof, Locales } from 'utils';
import { API } from 'services';

import './style.css';

//--Constants
const DOSICASE_PER_PAGE = 10;

class CustomerScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dosicases: props.dosicases,
      showCustomerEditModal: false,
      showCustomerDeleteModal: false,
      showDosicaseLinkModal: false,
      dosicaseSearchCriteria: ''
    };

    this.toggleCustomerEditModal = this.toggleCustomerEditModal.bind(this);
    this.handleCloseCustomerEditModal = this.handleCloseCustomerEditModal.bind(this);

    this.toggleCustomerDeleteModal = this.toggleCustomerDeleteModal.bind(this);
    this.handleCloseCustomerDeleteModal = this.handleCloseCustomerDeleteModal.bind(this);

    this.toggleDosicaseLinkModal = this.toggleDosicaseLinkModal.bind(this);
    this.handleCloseDosicaseLinkModal = this.handleCloseDosicaseLinkModal.bind(this);

    this.handleDosicaseSearch = this.handleDosicaseSearch.bind(this);
    this.handleDosicaseSelect = this.handleDosicaseSelect.bind(this);
  }

  componentWillMount() {
    this.updateBreadcrumb();
  }

  updateBreadcrumb() {
    BreadcrumbHelper.reset();
    BreadcrumbHelper.push('Accueil', '/');
    BreadcrumbHelper.push('Clients', '/customers');
    BreadcrumbHelper.push(<Text style="capitalize">{`${this.props.customer.company}`}</Text>, `/customers?s=${this.props.customer.company}`)
    BreadcrumbHelper.push(
      <TextGroup>
        <Text style="uppercase">{this.props.customer.lastname}</Text>
        <Text style="capitalize">{this.props.customer.firstname}</Text>
      </TextGroup>
    );
    BreadcrumbHelper.flush();
  }

  //--Events

  toggleCustomerEditModal() {
    this.setState({
      showCustomerEditModal: !this.state.showCustomerEditModal
    });
  }

  handleCloseCustomerEditModal(edit) {

    if(Typeof.object(edit)) {

      //Update
      Object.keys(edit).forEach((key) => {
        this.props.customer[key] = edit[key];
      });

      //Refresh
      this.updateBreadcrumb();
    }

    //Hide modal
    this.toggleCustomerEditModal();
  }

  toggleCustomerDeleteModal() {

    //Show modal
    this.setState({
      showCustomerDeleteModal: !this.state.showCustomerDeleteModal
    });
  }

  handleCloseCustomerDeleteModal(result, setProcessing) {

    if(result === ModalOptions.OPTION_YES) {

      setProcessing(result);

      API.delete(`/customers/${this.props.customer.id}`)
        .then(() => {

          //Go back to customers list
          this.props.history.push('/customers');
        });

    }

    //Hide modal
    this.toggleCustomerDeleteModal();
  }

  toggleDosicaseLinkModal() {

    //Show modal
    this.setState({
      showDosicaseLinkModal: !this.state.showDosicaseLinkModal
    });
  }

  handleCloseDosicaseLinkModal(dosicase) {

    if(Typeof.object(dosicase)) {

      let dosicases = [...this.state.dosicases];
      dosicases.push(dosicase);

      this.setState({
        dosicases: dosicases
      });
    }

    //Hide modal
    this.toggleDosicaseLinkModal();
  }

  handleDosicaseSearch(criteria) {

    this.setState({
      dosicaseSearchCriteria: criteria
    });

  }

  handleDosicaseSelect(dosicase) {

    //Navigate to DosiCase page
    this.props.history.push(`/dosicases/${dosicase.id}`);
  }

  render() {

    const { customer } = this.props;
    const { dosicases } = this.state;

    let customerInfos = [
      {
        name: 'Société',
        value: <Link to={`/customers?s=${customer.company}`}><Text style="capitalize">{customer.company}</Text></Link>
      },
      {
        name: 'Nom',
        value: <Text style="uppercase">{customer.lastname}</Text>
      },
      {
        name: 'Prénom',
        value: <Text style="capitalize">{customer.firstname}</Text>
      },
      {
        name: 'Adresse e-mail',
        value: <ExternalLink target={`mailto:${customer.email}`}>{customer.email}</ExternalLink>
      },
      {
        name: 'Numéro de tél.',
        value: <Text style="mono">{customer.phone}</Text>
      },
      {
        name: 'Adresse',
        value: customer.address
      },
      {
        name: 'Code postal',
        value: customer.zip_code
      },
      {
        name: 'Ville',
        value: customer.city
      },
      {
        name: 'Pays',
        value: <Country country={customer.country} />
      },
      {
        name: 'Langue',
        value: Locales.getLocale(customer.locale).name
      }
    ];

    let columns = [
      {
        id: 'id',
        header: '#',
        value: (dosicase) => dosicase.id,
        render: (dosicase, value) => <Index value={value} />,
        fit: true
      },
      {
        id: 'version',
        header: 'Version',
        value: (dosicase) => dosicase.version,
        render: (dosicase, value) => Typeof.string(value) ? <Link to={`/versions/${value}`} onClick={e => e.stopPropagation()}>{value}</Link> : <Empty />
      },
      {
        id: 'install_date',
        header: "Date d'installation",
        value: (dosicase) => Typeof.string(dosicase.install_date) ? moment(dosicase.install_date).format('DD/MM/YYYY HH:mm:ss') : undefined,
        render: (dosicase) => Typeof.string(dosicase.install_date) ? <Text style="mono">{moment(dosicase.install_date).format('[Le] DD/MM/YYYY [à] HH:mm:ss')}</Text> : <Empty />
      },
      {
        id: 'delivery_date',
        header: 'Date de livraison',
        value: (dosicase) => Typeof.string(dosicase.delivery_date) ? moment(dosicase.delivery_date).format('DD/MM/YYYY HH:mm:ss') : undefined,
        render: (dosicase) => Typeof.string(dosicase.delivery_date) ? <Text style="mono">{moment(dosicase.delivery_date).format('DD/MM/YYYY')}</Text> : <Empty />
      },
      {
        id: 'contract',
        header: 'Echéance contrat de maintenance',
        value: (dosicase) => Typeof.object(dosicase.contract) ? moment(dosicase.contract.due_date).format('DD/MM/YYYY') : undefined,
        render: (dosicase) => {

          if(Typeof.object(dosicase.contract)) {
            return (
              <div className="contract-infos">
                <ContractDate contract={dosicase.contract} />
                <FileActions endpoint={`dosicases/contracts/${dosicase.contract.id}`} />
              </div>
            );
          }

          return <Empty />;
        },
        sort: (a, b) => {

          let contractA = a.contract;
          let contractB = b.contract;

          let hasContractA = Typeof.object(contractA);
          let hasContractB = Typeof.object(contractB);

          if(!hasContractA && hasContractB) {
            return -1;
          }

          if(!hasContractB && hasContractA) {
            return 1;
          }

          if(!hasContractA && !hasContractB) {
            return 0;
          }

          return moment(contractA.due_date).diff(moment(contractB.due_date));
        }
      }
    ];

    return (
      <React.Fragment>

        {/* Informations */}
        <Container>

          <Section title="Informations" actions={[
            <Button icon="edit" circular color="yellow" onClick={this.toggleCustomerEditModal} key={0} />,
            <Button icon="trash" circular color="red" onClick={this.toggleCustomerDeleteModal} key={1} />
          ]}>

            <Properties properties={customerInfos} />

          </Section>

        </Container>

        {/* DosiCases */}
        <Container>

          <Section title="Valises" onSearch={this.handleDosicaseSearch} actions={[
            <Button icon="add" content="Associer" onClick={this.toggleDosicaseLinkModal} color="yellow" />
          ]}>

            {dosicases.length > 0 ? (

              <React.Fragment>

                {/* Number of available DosiCase */}
                <InfosBar mode="column">
                  <span>{dosicases.length > 1 ? `${dosicases.length} valises associées` : '1 valise associée'}</span>
                </InfosBar>

                {/* Dosicases table */}
                <Table columns={columns}
                  criteria={this.state.dosicaseSearchCriteria}
                  items={dosicases}
                  itemsPerPage={DOSICASE_PER_PAGE}
                  onSelect={this.handleDosicaseSelect}
                  messageEmpty="Aucune valise ne correspond à votre recherche"
                />

              </React.Fragment>

            ) : (

              // No DosiCase available
              <InfosBar mode="row">
                <span>Aucune valise disponible</span>
              </InfosBar>

            )}


          </Section>

        </Container>

        {/* Customer edit modal */}
        {this.state.showCustomerEditModal &&
          <ModalCustomer onClose={this.handleCloseCustomerEditModal} customer={customer} />
        }

        {/* Customer delete warning modal */}
        {this.state.showCustomerDeleteModal &&
          <ModalOptions onClose={this.handleCloseCustomerDeleteModal} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de supprimer ce client.<br />La réalisation de cette opération dissocieras les valises associées.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

        {/* Dosicase link modal */}
        {this.state.showDosicaseLinkModal &&
          <ModalLinkDosicase onClose={this.handleCloseDosicaseLinkModal} customer={customer} />
        }

      </React.Fragment>
    );
  }
}

export default withRouter(CustomerScene);
