import React from 'react'
import PropTypes from 'prop-types'

import { Section, Properties, Blurred, Empty, Text } from 'components';
import { Button, Icon } from 'semantic-ui-react';

import { ModalEditDosicaseAccount } from 'modals';

import { Typeof, Constants } from 'utils';
import moment from 'moment';

import { SecurityQuestionGroup1, SecurityQuestionGroup2, SecurityQuestionGroup3 } from 'enums';

class SectionAccount extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);

    this.getSecurityQuestion = this.getSecurityQuestion.bind(this);
  }

  toggleEditModal() {

    //Show modal
    this.setState({
      showEditModal: !this.state.showEditModal
    });
  }

  handleCloseEditModal(account) {

    if(Typeof.object(account)) {

      //Update locally
      this.props.dosicase.account = account;
    }

    //Hide modal
    this.toggleEditModal();
  }

  getSecurityQuestion(index, question, anwser) {

    let group = null;

    switch (index) {
      case 1:
        group = SecurityQuestionGroup1;
        break;
      case 2:
        group = SecurityQuestionGroup2;
        break;
      case 3:
        group = SecurityQuestionGroup3;
        break;
    }

    return (
      <div className="security-question">

        {Typeof.string(question) && Typeof.string(anwser) ? (
          <React.Fragment>
            <span className="question">{group.name(question)}</span>
            <span className="answer">{anwser}</span>
          </React.Fragment>
        ) : (
          <Empty />
        )}

      </div>
    );
  }

  render() {

    const { account } = this.props.dosicase;

    let accountProperties = [
      {
        name: 'Date de naissance',
        value: Typeof.string(account.birthdate) ? moment(account.birthdate).format('DD/MM/YYYY') : <Empty />
      },
      {
        name: 'Nom',
        value: Typeof.string(account.lastname) ? <Text style="uppercase">{account.lastname}</Text> : <Empty />
      },
      {
        name: 'Prénom',
        value: Typeof.string(account.firstname) ? <Text style="capitalize">{account.firstname}</Text> : <Empty />
      },
      {
        name: 'Mot de passe',
        value: Typeof.string(account.password) ? <Blurred value={account.password} /> : <Empty />
      },
      {
        name: 'Question de sécurité n°1',
        value: this.getSecurityQuestion(1, account.security_question_1, account.security_question_answer_1)
      },
      {
        name: 'Question de sécurité n°2',
        value: this.getSecurityQuestion(2, account.security_question_2, account.security_question_answer_2)
      },
      {
        name: 'Question de sécurité n°3',
        value: this.getSecurityQuestion(3, account.security_question_3, account.security_question_answer_3)
      }
    ];

    return (
      <Section title={<span><Icon name="apple" />Apple ID</span>} actions={
        <Button icon="edit" color="yellow" circular onClick={this.toggleEditModal} />
      } collapsible collapsed>

        <Properties properties={accountProperties} />

        {/* Account edit modal */}
        {this.state.showEditModal &&
          <ModalEditDosicaseAccount onClose={this.handleCloseEditModal} dosicase={this.props.dosicase} />
        }

      </Section>


    );
  }
}

export default SectionAccount;
