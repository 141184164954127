
/**
 * Utilities to test data types
 *
 * @author Matthieu Renault <matthieu.renault@ob-do.com>
 */
class Typeof {

  /**
   *
   * @param   {Function} typeOf the typeof function to test
   * @param   {*}        value  the value to test
   * @param   {*}        fallback the fallback value
   * @returns {String}                 the value if the typeof function test pass otherwise the fallback value
   */
  static get(typeOf, value, fallback = undefined) {
    return typeOf(value) ? value : fallback;
  }

  /**
   * Indicates if the given value is a string
   * @param  {*}    value the value to check
   * @return {bool}       true if the value is a string otherwise false
   */
  static string(value) {
    return typeof value === 'string';
  }

  /**
   * Indicates if the given value is a number
   * @param  {*}    value the value to check
   * @return {bool}       true if the value is a number otherwise false
   */
  static number(value) {
    return typeof value === 'number' && !isNaN(value);
  }

  /**
   * Indicates if the given value is a boolean
   * @param  {*}    value the value to check
   * @return {bool}       true if the value is a boolean otherwise false
   */
  static bool(value) {
    return typeof value === 'boolean';
  }

  /**
   * Indicates if the given value is an object
   * @param  {*}    value the value to check
   * @return {bool}       true if the value is an object otherwise false
   */
  static object(value) {
    return typeof value === 'object' && value !== null;
  }

  /**
   * Indicates if the given value is an array
   * @param  {*}    value the value to check
   * @return {bool}       true if the value is an array otherwise false
   */
  static array(value) {
    return Array.isArray(value);
  }


  /**
   * Indicates if the given value is a symbol
   * @param  {*}    value the value to check
   * @return {bool}       true if the value is a symbol otherwise false
   */
  static symbol(value) {
    return typeof value === 'symbol';
  }

  /**
   * Indicates if the given value is a function
   * @param  {*}    value the value to check
   * @return {bool}       true if the value is a function otherwise false
   */
  static func(value) {
    return typeof value === 'function';
  }

  /**
   * Indicates if the given value is undefined
   * @param  {*}    value the value to check
   * @return {bool}       true if the value is undefined otherwise false
   */
  static undefined(value) {
    return typeof value === 'undefined';
  }

  /**
   * Indicates if the given value is defined
   * @param  {*}    value the value to check
   * @return {bool}       true if the value is defined otherwise false
   */
  static defined(value) {
    return !Typeof.undefined(value);
  }

  /**
   * Indicates if the given value is null
   * @param  {*}  value the value to check
   * @return {bool}     true if the value is null otherwise false
   */
  static null(value) {
    return value === null;
  }

  /**
   * Indicates if the given value is blank (undefined, null or '')
   * @param  {*}  value the value to check
   * @return {bool}     true if the value is blank otherwise false
   */
  static blank(value) {
    return Typeof.undefined(value) || Typeof.null(value) || value === "";
  }
}

export default Typeof;
