import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames';

import { Card, Checkbox, Divider, Form, Button, Label } from 'semantic-ui-react';

import { Typeof } from 'utils';

import './style.css';

const TYPES = {
  NONE: 'NONE',
  INTEGER: 'INTEGER',
  DOUBLE: 'DOUBLE',
  STRING: 'STRING',
  JSON: 'JSON',
  ARRAY: 'ARRAY<?>',
  ARRAY_INTEGER: 'ARRAY<INTEGER>',
  ARRAY_DOUBLE: 'ARRAY<DOUBLE>',
  ARRAY_STRING: 'ARRAY<STRING>',
  ARRAY_JSON: 'ARRAY<JSON>',
};

const EMPTY_STRING = '%vide%';

class Option extends React.Component {

  constructor(props) {
    super(props);

    let option = props.option;
    let value = props.value;

    switch (option.data_type) {

      default:
      case TYPES.NONE:
        break;

      //Special case for empty number
      case TYPES.INTEGER:
      case TYPES.DOUBLE:

        if(Typeof.undefined(value)) {
          value = '';
        }

        break;

      //Special case for empty string
      case TYPES.STRING:

        if(Typeof.undefined(value)) {
          value = '';
        } else if(value.length === 0) {
          value = EMPTY_STRING;
        }

        break;

      case TYPES.JSON:

        if(Typeof.undefined(value)) {
          value = '';
        } else {

          try {
            value = JSON.stringify(JSON.parse(value), null, 2);
          } catch(error) {
            value = '/* Invalid JSON */';
          }
        }

        break;

      case TYPES.ARRAY:
      case TYPES.ARRAY_INTEGER:
      case TYPES.ARRAY_DOUBLE:
      case TYPES.ARRAY_STRING:
      case TYPES.ARRAY_JSON:

        if(Typeof.undefined(value)) {
          value = '';
        } else {

          try {
            value = JSON.stringify(JSON.parse(value), null, 2);
          } catch(error) {
            value = '/* Invalid array */';
          }
        }

        break;

    }

    this.state = {
      value: value,
      pending: false,
      error: null,
      dirty: false
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleValueSave = this.handleValueSave.bind(this);
  }

  handleToggle(toggle) {

    if(!this.state.pending) {

      this.setState({
        pending: true
       }, () => {

        this.props.onToggle(this.props.option, toggle, () => {

          this.setState({
            pending: false
          });

        });

      });

    }
  }

  handleValueChange(value) {

    this.setState({
      value: value,
      dirty: true
    });

  }

  validate(value) {

    //Stric mode: No empty value
    if(this.props.strict && value.length === 0) {
      throw new Error("Ne peut pas être vide");
    }

    //Empty value for NULL
    if(value.length === 0) {
      return undefined;
    }

    //Validate value
    switch (this.props.option.data_type) {
      default:
      case TYPES.NONE:
        return undefined;

      case TYPES.INTEGER: {

        if(Number(value) !== parseInt(Number(value), 10)) {
          throw new Error("Doit être un nombre entier");
        } else {
          return Number(value);
        }
      }

      case TYPES.DOUBLE: {
        return Number(value);
      }

      case TYPES.STRING: {

        if(value === EMPTY_STRING) {
          return '';
        } else {
          return value;
        }
      }

      case TYPES.JSON: {

        try {
          return JSON.parse(value);
        } catch (error) {
          throw new Error("Doit être au format JSON");
        }

      }

      case TYPES.ARRAY: {
        return this.validateArray(value);
      }
      case TYPES.ARRAY_INTEGER: {
        return this.validateArray(value, Number.isInteger, "Ne peut contenir que des nombres entiers");
      }
      case TYPES.ARRAY_DOUBLE: {
        return this.validateArray(value, Number.isFinite, "Ne peut contenir que des nombres à virgules");
      }
      case TYPES.ARRAY_STRING: {
        return this.validateArray(value, Typeof.string, "Ne peut contenir que des chaînes de caractères");
      }
      case TYPES.ARRAY_JSON: {
        return this.validateArray(value, Typeof.object, "Ne peut contenir que des données JSON");
      }
    }
  }

  validateArray(value, typeCheck = null, wrongTypeMessage) {
    let array = null

    try {
      array = JSON.parse(value);

    } catch (error) {
      throw new Error("Doit être un tableau au format JSON");
    }

    if(!Array.isArray(array)) {
      throw new Error("Doit être un tableau au format JSON");
    }

    if(Typeof.func(typeCheck)) {

      array.forEach((e, index) => {

        if(!typeCheck(e)) {
          throw new Error(`${wrongTypeMessage} (#${index})`);
        }

      });

    }

    return array;
  }

  handleValueSave() {

    if(!this.state.pending) {

      try {
        let value = this.validate(this.state.value.trim());

        this.setState({
          error: null,
          pending: true
        }, () => {

          this.props.onValueChange(this.props.option, value, () => {

            this.setState({
              pending: false,
              dirty: false
            });

          });

        });

      } catch(error) {
        this.setState({
          error: error.message
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.active && !this.props.active) {

      let state = {};

      //Clear input
      if(Typeof.defined(prevState.value)) {
        state.value = '';
      }

      //Reset pending state
      if(prevState.pending || prevState.dirty || prevState.error) {
        state.pending = false;
        state.dirty = false;
        state.error = null;
      }

      if(Object.keys(state).length > 0) {
        this.setState(state);
      }
    }
  }

  render() {

    let option = this.props.option;

    //Flags
    let active = this.props.active;
    let pending = this.state.pending;

    let classes = classnames('option', { 'active': active });
    let color = pending ? 'yellow' : active ? 'green' : 'red';

    let input = null;
    let dataType;

    if(option.data_type !== 'NONE') {

      let editable = !pending && active;

      let props = {
        placeholder: option.default_value,
        readOnly: !editable,
        onChange: (event) => this.handleValueChange(event.target.value)
      };

      let value = this.state.value;

      // eslint-disable-next-line default-case
      switch (option.data_type) {
        case TYPES.INTEGER:
          dataType = 'Nombre entier';
          input = <input type="number" step="1" value={value} {...props} />
          break;
        case TYPES.DOUBLE:
          dataType = 'Nombre à virgule';
          input = <input type="number" value={value} {...props} />
          break;
        case TYPES.STRING:
          dataType = 'Chaîne de caractères';
          input = <input type="text" value={value} {...props} className={classnames({ 'empty': value === EMPTY_STRING })} />
          break;
        case TYPES.JSON:
          dataType = 'JSON';
          input = <textarea value={value} {...props} />
          break;
        case TYPES.ARRAY:
          dataType = 'Tableau (tout)';
          input = <textarea value={value} {...props} />
          break;
        case TYPES.ARRAY_INTEGER:
          dataType = 'Tableau (nombre entier)';
          input = <input type="text" value={value} {...props} />
          break;
        case TYPES.ARRAY_DOUBLE:
          dataType = 'Tableau (nombre à virgule)';
          input = <input type="text" value={value} {...props} />
          break;
        case TYPES.ARRAY_STRING:
          dataType = 'Tableau (chaîne de caractères)';
          input = <input type="text" value={value} {...props} />
          break;
        case TYPES.ARRAY_JSON:
          dataType = 'Tableau (JSON)';
          input = <textarea value={value} {...props} />
          break;
      }
    }

    return (
      <Card fluid color={color} key={option.id} className={classes} raised>
        <div className="option-header">

          <div className="option-infos">

            {/* Name */}
            <span className="option-name">{option.name}</span>

            {/* ID */}
            <span className="option-id"># {option.id}</span>

          </div>

          {/* Toggle */}
          {this.props.toggle &&
            <Checkbox slider checked={active} className="option-toggle" onClick={(event, { checked }) => this.handleToggle(checked)} disabled={this.state.pending} />
          }

        </div>

        <Card.Content>

          {/* Description */}
          <Card.Description>{option.description}</Card.Description>

          {/* Parameter */}
          {input !== null &&
            <React.Fragment>

              <Divider />

              <Form as="div">
                <Form.Field>
                  <label className="option-parameter-infos">
                    Paramètre
                    <Label size="mini" content={dataType} />
                  </label>
                  <div className={classnames('option-parameter', { 'dirty': this.state.dirty, 'invalid': this.state.error !== null})}>

                    {/* Input */}
                    {input}

                    {/* Save */}
                    {this.state.dirty &&
                      <Button color="yellow" icon="save" loading={pending} onClick={this.handleValueSave} disabled={pending} />
                    }

                  </div>

                  {/* Validation error */}
                  <span className="option-parameter-feedback">{this.state.error}</span>
                </Form.Field>
              </Form>

            </React.Fragment>
          }
        </Card.Content>
      </Card>
    );
  }
};

Option.defaultProps = {
  toggle: false,
  strict: false,
};

Option.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    default_value: PropTypes.string
  }),
  toggle: PropTypes.bool,
  strict: PropTypes.bool,
  onToggle: PropTypes.func,
  active: PropTypes.bool.isRequired,
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired
};

export default Option;
