import React from 'react'
import PropTypes from 'prop-types'

import { debounce } from 'lodash';

import { Container, Button, Card, Pagination, Breadcrumb, Dropdown } from 'semantic-ui-react';
import { Section, InfosBar } from 'components';

import { ModalOptions, ModalLogbookEntry } from 'modals';

import { Typeof } from 'utils';
import { Env } from 'env';
import { API } from 'services';

import classnames from 'classnames';

import moment from 'moment';

import './style.css';

const ENTRIES_PER_PAGE = 5;

const FILTER_ALL    = 0;
const FILTER_MANTIS = 1

const FILTERS = [
  {
    key: 0,
    text: 'Tout',
    value: FILTER_ALL
  },
  {
    key: 1,
    text: 'Mantis',
    value: FILTER_MANTIS
  }
];

class PaneLogbook extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      entries: [],
      entriesFilter: FILTER_ALL,
      activePage: 1,
      searchCriteria: '',
      targetEntry: null,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false
    };

    this.handleFilter = this.handleFilter.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);

    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.handleCloseCreateModal = this.handleCloseCreateModal.bind(this);

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
  }

  handleFilter(event, { value }) {

    this.setState({
      entriesFilter: value,
      activePage: 1
    });
  }

  handleSearch = debounce((criteria) => {

    this.setState({
      searchCriteria: criteria,
      activePage: 1
    });

  }, 250);


  handlePaginationChange(e, { activePage }) {

    this.setState({ activePage });
  }

  toggleCreateModal() {

    //Show modal
    this.setState({
      showCreateModal: !this.state.showCreateModal
    });
  }

  handleCloseCreateModal(entry) {

    if(Typeof.object(entry)) {

      //Update locally
      this.props.dosicase.logbook.push(entry);
      this.forceUpdate();
    }

    //Hide modal
    this.toggleCreateModal();
  }

  toggleEditModal(entry) {

    //Show modal
    this.setState({
      targetEntry: entry,
      showEditModal: !this.state.showEditModal
    });
  }

  handleCloseEditModal(update) {

    if(Typeof.object(update)) {

      //Update locally
      this.props.dosicase.logbook = this.props.dosicase.logbook.map((entry) => {

        if(entry.id === update.id) {
          entry.date = update.date;
          entry.subject = update.subject;
          entry.text = update.text;
          entry.mantis = update.mantis;
        }

        return entry;
      });
      this.forceUpdate();
    }

    //Hide modal
    this.toggleEditModal();
  }

  toggleDeleteModal(entry = null) {

    //Show modal
    this.setState({
      targetEntry: entry,
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  handleCloseDeleteModal(result, setProcessing) {

    if(result !== ModalOptions.OPTION_YES) {
      this.toggleDeleteModal();
    } else {

      setProcessing(true);

      API.delete(`/dosicases/logbook/${this.state.targetEntry.id}`)
        .then(() => {

          //Remove locally
          this.props.dosicase.logbook = this.props.dosicase.logbook.filter((entry) => entry.id !== this.state.targetEntry.id);

          let entries = this.getEntries();
          let page = this.state.activePage;

          if(entries.length > 0) {

            let totalPages = Math.ceil(entries.length / ENTRIES_PER_PAGE);

            if(page > totalPages) {
              page = totalPages;
            }
          }

          this.setState({
            activePage: page,
            targetEntry: null,
            showDeleteModal: false
          });

        });
    }
  }

  getEntries() {

    const { dosicase } = this.props;

    let availableEntries = dosicase.logbook;
    let entries = [];

    if(availableEntries.length > 0) {

      let filter = this.state.entriesFilter;

      //Filter
      availableEntries = availableEntries.filter((entry) => {

        switch(filter) {
          case FILTER_MANTIS:
            return Typeof.number(entry.mantis);

          default:
            return true;
        }
      });

      let critera = this.state.searchCriteria;

      //Filter
      if(Typeof.string(critera) && critera.length > 0) {

        entries = availableEntries.filter((entry) => {

          let fields = [
            entry.subject,
            moment(entry.date).format('DD/MM/YYYY'),
            moment(entry.date).format('HH:mm:ss')
          ]

          if(Typeof.string(entry.text)) {
            fields.push(entry.text);
          }

          if(Typeof.number(entry.mantis)) {
            fields.push(`#${entry.mantis}`);
          }

          return fields.some((v) => v.includes(critera));
        })

      } else {
        entries = availableEntries;
      }

      //Sort newer first
      entries.sort((a, b) => {

        return b.date.localeCompare(a.date);
      });
    }

    return entries;
  }

  render() {

    const { dosicase } = this.props;

    let availableEntries = dosicase.logbook;
    let entries = this.getEntries();
    let totalPages = 0

    //Limit
    if(entries.length > 0) {

      totalPages = Math.ceil(entries.length / ENTRIES_PER_PAGE);

      let start = (this.state.activePage - 1) * ENTRIES_PER_PAGE;
      let end = start + ENTRIES_PER_PAGE;

      entries = entries.slice(start, end);
    }

    return (
      <React.Fragment>

        <Container id="logbook">
          <Section title="Journal de bord" onSearch={this.handleSearch} actions={[
            <Dropdown icon="filter" value={this.state.entriesFilter} options={FILTERS} onChange={this.handleFilter} direction='left' key={0} />,
            <Button icon="add" color="yellow" onClick={this.toggleCreateModal} content="Nouveau" key={1} />,
          ]} >

            {availableEntries.length > 0 ? (

              <React.Fragment>

                {entries.length > 0 ? (

                  <React.Fragment>

                    {/* Number of available entries */}
                    <InfosBar mode="column">
                      <span>{entries.length > 1 ? `${entries.length} entrées disponibles` : '1 entrée disponible'}</span>
                    </InfosBar>

                    {/* Entries */}
                    {entries.map((entry, i) => {

                      return (
                        <Card fluid key={i} className={classnames("entry", { "mantis": Typeof.number(entry.mantis) })} color='yellow' raised>

                          {/* Description */}
                          <Card.Content>

                            <div className="header">

                              {/* Subject */}
                              <span className="subject">{entry.subject}</span>

                              {/* Mantis URL */}
                              {Typeof.number(entry.mantis) &&
                                <a className="mantis" href={`${Env.MANTIS_URL}/view.php?id=${entry.mantis}`} target="_blank_">{`#${entry.mantis}`}</a>
                              }
                            </div>

                            {/* Content */}
                            {Typeof.string(entry.text) &&
                              <span className="content">{entry.text}</span>
                            }

                            {/* Footer */}
                            <div className="footer">

                              {/* Date */}
                              <span className="date">
                                {moment(entry.date).format('[Le] DD/MM/YYYY [à] HH:mm:ss')}
                              </span>

                              {/* Actions */}
                              <Breadcrumb size="mini" className="actions">

                                {/* Edit */}
                                <Breadcrumb.Section link onClick={() => this.toggleEditModal(entry)}>
                                  Modifier
                                </Breadcrumb.Section>

                                <Breadcrumb.Divider />

                                {/* Delete */}
                                <Breadcrumb.Section link onClick={() => this.toggleDeleteModal(entry)}>
                                  Supprimer
                                </Breadcrumb.Section>
                              </Breadcrumb>
                            </div>
                          </Card.Content>
                        </Card>
                      )
                    })}

                    {/* Pagination */}
                    <div className="pagination">
                      <Pagination activePage={this.state.activePage} totalPages={totalPages} onPageChange={this.handlePaginationChange} />
                    </div>

                  </React.Fragment>

                ) : (

                  // No entry available for critera
                  <InfosBar mode="row">
                    <span>Aucune entrée ne correspond à votre recherche</span>
                  </InfosBar>
                )}

              </React.Fragment>
            ) : (

              // No entry available
              <InfosBar mode="row">
                <span>Aucune entrée dans le journal de bord</span>
              </InfosBar>
            )}

          </Section>

        </Container>

        {/* Entry create modal */}
        {this.state.showCreateModal &&
          <ModalLogbookEntry dosicase={dosicase} onClose={this.handleCloseCreateModal} />
        }

        {/* Entry edit modal */}
        {this.state.showEditModal &&
          <ModalLogbookEntry dosicase={dosicase} entry={this.state.targetEntry} onClose={this.handleCloseEditModal} />
        }

        {/* Entry delete warning modal */}
        {this.state.showDeleteModal &&
          <ModalOptions onClose={this.handleCloseDeleteModal} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de supprimer cette entrée.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

      </React.Fragment>
    );
  }
}

export default PaneLogbook;
