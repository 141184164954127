import React from 'react'
import PropTypes from 'prop-types'

import { Container, Button, Popup, Icon } from 'semantic-ui-react';
import { Section, InfosBar, Table, FileActions } from 'components';

import { ModalOptions, ModalDosicaseDocument } from 'modals';

import { API } from 'services';

import { Typeof } from 'utils';

import moment from 'moment';

import './style.css';

const DOCUMENTS_PER_PAGE = 12;

class PaneDocuments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      criteria: '',
      targetDocument: null,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false
    };

    this.handleSearch = this.handleSearch.bind(this);

    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.handleCloseCreateModal = this.handleCloseCreateModal.bind(this);

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
  }

  handleSearch(criteria) {
    this.setState({
      criteria: criteria
    });
  }

  toggleCreateModal() {

    this.setState({
      showCreateModal: !this.state.showCreateModal,
    });
  }

  handleCloseCreateModal(document) {

    if(Typeof.object(document)) {

      this.props.dosicase.documents.push(document);
    }

    this.toggleCreateModal();
  }

  toggleEditModal(document = null) {

    this.setState({
      targetDocument: document,
      showEditModal: !this.state.showEditModal,
    });
  }

  handleCloseEditModal(edit) {

    if(Typeof.object(edit)) {

      //Update locally
      this.props.dosicase.documents = this.props.dosicase.documents.map((document) => {

        if(document.id === edit.id) {
          document.name = edit.name;
          document.date_update = edit.date_update;
        }

        return document;
      });
    }

    this.toggleEditModal();
  }

  toggleDeleteModal(document = null) {
    this.setState({
      targetDocument: document,
      showDeleteModal: !this.state.showDeleteModal
    });
  }

  handleCloseDeleteModal(result, setProcessing) {

    if (result !== ModalOptions.OPTION_YES) {
      this.toggleDeleteModal();
    } else {

      setProcessing(true);

      API.delete(`/dosicases/documents/${this.state.targetDocument.id}`)
        .then(() => {

          //Remove locally
          this.props.dosicase.documents = this.props.dosicase.documents.filter((document) => document.id !== this.state.targetDocument.id);

          this.toggleDeleteModal();
        });
    }

  }

  getIconForExt(ext) {

    switch(ext) {
      case 'svg':
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'file image';
      case 'wav':
      case 'mp4':
      case 'avi':
        return 'file video';
      case 'mp3':
      case 'ogg':
      case 'flac':
        return 'file audio';
      case 'pdf':
        return 'file pdf';
      case 'zip':
        return 'file archive';
      default:
        return 'file';
    }

  }

  render() {

    const { dosicase } = this.props;

    const documents = dosicase.documents;

    let columns = [
      {
        id: 'name',
        header: 'Nom',
        value: (document) => document.name,
        render: (document, value) => {
          return (
            <div className="file">

              <div>

                {/* Icon */}
                <Popup position="bottom center" size='mini' content={`.${document.extension}`} trigger={
                  <Icon name={this.getIconForExt(document.extension)} color="grey" />
                } />

                {/* Name */}
                {value}

              </div>

              {/* Edit */}
              <Icon name="pencil" className="edit" link onClick={() => this.toggleEditModal(document)} />

            </div>
          )
        }
      },
      {
        id: 'date_create',
        header: 'Créé le',
        value: (document) => moment(document.date_create).format('DD/MM/YYYY HH:mm:ss'),
        render: (document) => moment(document.date_create).format('DD/MM/YYYY [à] HH:mm:ss'),
        fit: true
      },
      {
        id: 'date_update',
        header: 'Modifié le',
        value: (document) => moment(document.date_update).format('DD/MM/YYYY HH:mm:ss'),
        render: (document) => moment(document.date_update).format('DD/MM/YYYY [à] HH:mm:ss'),
        fit: true
      },
      {
        id: 'actions',
        header: "Actions",
        value: (document) => undefined,
        render: (document) => (
          <FileActions endpoint={`dosicases/documents/${document.id}`} actions={[
            ...FileActions.DEFAULT_ACTIONS,
            {
              icon: 'trash',
              content: 'Supprimer',
              handle: () => this.toggleDeleteModal(document),
              className: 'contract-delete'
            }
          ]} />
        ),
        fit: true
      },
    ];

    return (
      <React.Fragment>

        {/* List */}
        <Container>
          <Section title="Liste des documents" onSearch={this.handleSearch} actions={
            <Button icon="add" color="yellow" onClick={this.toggleCreateModal} content="Ajouter" />
          } >

            {documents.length > 0 ? (

              <React.Fragment>

                {/* Number of available documents */}
                <InfosBar mode="column">
                  <span>{documents.length > 1 ? `${documents.length} documents disponibles` : '1 document disponible'}</span>
                </InfosBar>

                {/* Customers list */}
                <Table columns={columns}
                  criteria={this.state.criteria}
                  items={documents}
                  itemsPerPage={DOCUMENTS_PER_PAGE}
                  onSelect={this.handleCustomerSelect}
                  messageEmpty="Aucun document ne correspond à votre recherche"
                />

              </React.Fragment>

            ) : (

              // No customer available
              <InfosBar mode="row">
                <span>Aucun document disponible</span>
              </InfosBar>

            )}

          </Section>

        </Container>

        {/* Document create modal */}
        {this.state.showCreateModal &&
          <ModalDosicaseDocument onClose={this.handleCloseCreateModal} dosicase={dosicase} />
        }

        {/* Document edit modal */}
        {this.state.showEditModal &&
          <ModalDosicaseDocument onClose={this.handleCloseEditModal} dosicase={dosicase} document={this.state.targetDocument} />
        }

        {/* Document delete warning modal */}
        {this.state.showDeleteModal &&
          <ModalOptions onClose={this.handleCloseDeleteModal} icon="trash alternate" title="Suppression" message={<span>Vous êtes sur le point de supprimer ce document.<br /><br />Voulez vous continuer ?</span>} options={ModalOptions.OPTIONS_YESCANCEL} />
        }

      </React.Fragment>
    );
  }
}

export default PaneDocuments;
