import React from 'react'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { DosicaseScene } from 'scenes';

class DosicaseContainer extends AbstractContainer {

  getTasks(params) {

    let dosicase = params.dosicase;

    return [
      {
        key: 'dosicase',
        exec: (done, failure) => {
          API.get(`dosicases/${dosicase}`)
            .then((response) => {
              done({
                id: dosicase,
                ...response.data
              });
            })
            .catch(failure);
        }
      },
      {
        key: 'options',
        exec: (done, failure) => {
          API.get(`options/active`)
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      },
      {
        key: 'versions',
        exec: (done, failure) => {
          API.get(`versions`)
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <DosicaseScene dosicase={results.dosicase} versions={results.versions} options={results.options} />
  }
}

export default DosicaseContainer;
