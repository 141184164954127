import React from 'react'

import './style.css';

class Empty extends React.Component {
  render() {
    return (
      <span className="placeholder-empty">–</span>
    );
  }
}

export default Empty;
