import React from 'react'
import PropTypes from 'prop-types'

import { Container, Dropdown, Flag, Button, Message, Divider, Header, Popup, Segment } from 'semantic-ui-react';
import { Section, InfosBar, Empty } from 'components';

import { ModalEditMail } from 'modals';

import { Locales, Typeof, Constants } from 'utils';

import classnames from 'classnames';
import './style.css';

class MailScene extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedMail: null,
      selectedLocale: Locales.DEFAULT,
      showMailEditModal: false
    };

    this.getMailData = this.getMailData.bind(this);
    this.getVariableData = this.getVariableData.bind(this);

    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleLocaleChange = this.handleLocaleChange.bind(this);
    this.toggleMailEditModal = this.toggleMailEditModal.bind(this);
    this.handleCloseMailEditModal = this.handleCloseMailEditModal.bind(this);
  }

  handleMailChange(event, { value }) {
    this.setState({
      selectedMail: value,
      selectedLocale: Locales.DEFAULT
    });
  }

  handleLocaleChange(event, { value }) {
    this.setState({
      selectedLocale: value,
    });
  }

  toggleMailEditModal() {
    this.setState({
      showMailEditModal: !this.state.showMailEditModal
    });
  }

  handleCloseMailEditModal(mail) {

    //Update locally
    if(Typeof.object(mail)) {

      let data = this.props.mails.find((data) => {
        return data.id === mail.id && data.locale === mail.locale;
      });

      if(!Typeof.object(data)) {
        data = mail;
        this.props.mails.push(data);
      } else {
        data.subject = mail.subject;
        data.content = mail.content;
      }

      this.forceUpdate();
    }

    this.toggleMailEditModal();
  }

  getMailProperty(mail, data, property, highlight = true) {

    if(Typeof.object(data) && Typeof.string(data[property])) {

      let value = data[property].trim();

      if(value.length > 0) {

        if(highlight) {

          const regex = /\{\{\s*(\w[\w.]*)\s*\}\}/gm;

          let values = [];

          let i = 0;
          let match;

          while((match = regex.exec(value)) !== null) {
            let variable = match[0];

            let end = regex.lastIndex;
            let start = end - variable.length;

            let part = value.slice(i, start);
            i = end;

            let data = this.getVariableData(mail, match[1]);
            let isUnknown = data.hasOwnProperty('unknown');

            values.push(part);
            values.push(
              <Popup
                trigger={<span className={classnames('highlight', { 'unknown': isUnknown })}>{data.name}</span>}
                content={Typeof.object(data) ? data.description : 'Variable inconnue'}
                position="bottom center"
                inverted
              />
            );
          }

          if(i !== value.length) {
            values.push(value.slice(i));
          }

          if(values.length === 0 && value.length > 0) {
            return value;
          }

          return values;
        }

        return value;
      }
    }

    return undefined;
  }

  getVariableData(mail, name) {

    let variable = mail.variables.find((v) => v.name === name);

    if(!Typeof.object(variable)) {
      variable = {
        name: name,
        description: 'Variable inconnue',
        unknown: true
      }
    }

    return variable;
  }

  getMailData(id, locale) {
    return this.props.mails.find((mail) => mail.id === id && mail.locale === locale);
  }

  renderPrevisualization() {

    let mail = Constants.getMail(this.state.selectedMail);
    let data = this.getMailData(this.state.selectedMail, this.state.selectedLocale.code);

    let subject = this.getMailProperty(mail, data, 'subject');
    let content = this.getMailProperty(mail, data, 'content');

    let warning = null;

    if(Typeof.undefined(subject) || Typeof.undefined(content)) {

      if(this.state.selectedLocale.code === Locales.DEFAULT.code) {
        warning = `Les données de ce modèle sont incomplètes et ne permettent pas de l'utiliser`;
      } else {

        let defaultData = this.getMailData(this.state.selectedMail, Locales.DEFAULT.code);
        let defaultSubject = this.getMailProperty(mail, defaultData, 'subject', false);
        let defaultContent = this.getMailProperty(mail, defaultData, 'content', false);

        if (Typeof.undefined(defaultSubject) || Typeof.undefined(defaultContent)) {
          warning = `Les données de ce modèle ainsi que celles du modèle par défaut (${Locales.DEFAULT.name}) sont incomplètes et ne permettent pas de l'utiliser`;
        } else {
          warning = `Les données de ce modèle sont incomplètes et ne permettent pas de l'utiliser. Le modèle par défaut (${Locales.DEFAULT.name}) sera utilisé à la place`;
        }
      }
    }

    return (
      <React.Fragment>

        {/* Validity */}
        {Typeof.string(warning) &&
          <Message warning>
            {warning}
          </Message>
        }

        <Segment.Group className="mail-preview">

          {/* Subject */}
          <Segment className="subject">
            <span>Objet</span>
            <div style={{ direction: this.state.selectedLocale.direction }}>

              {Typeof.defined(subject) ? (
                subject
              ) : (
                <Empty />
              )}

            </div>
          </Segment>

          {/* Content */}
          <Segment style={{ direction: this.state.selectedLocale.direction }} className="content">
            {Typeof.defined(content) ? (
              content
            ) : (
              <Empty />
            )}
          </Segment>

        </Segment.Group>
      </React.Fragment>
    )
  }

  render() {

    let mailsList = Constants.MAILS.map((mail) => {

      return {
        key: mail.id,
        text: mail.name,
        value: mail.id
      };

    });

    let localesList = Locales.ALL.map((locale) => {

      return {
        key: locale.code,
        text: (
          <span>
            <Flag name={locale.flag.toLowerCase()} />
            {locale.name}
          </span>
        ),
        value: locale
      };

    });

    return (
      <Container>
        <Section title="E-mails" >

          <div className="mail-selector">
            <label>Modèle</label>

            <Dropdown
              placeholder='Séléctionnez un modèle'
              fluid
              selection
              options={mailsList}
              onChange={this.handleMailChange}
              value={this.state.selectedMail}
            />

            <label>Langue</label>
            <Dropdown
              placeholder='Séléctionnez une langue'
              fluid
              selection
              options={localesList}
              onChange={this.handleLocaleChange}
              value={this.state.selectedLocale}
              disabled={this.state.selectedMail === null}
            />

            <Button icon="edit" color="yellow" disabled={this.state.selectedMail === null} onClick={this.toggleMailEditModal} />

          </div>

          <Divider horizontal>
            <Header as='h4'>
              Prévisualisation
            </Header>
          </Divider>

          {this.state.selectedMail !== null ? (
            this.renderPrevisualization()
          ) : (
            <InfosBar mode="row">
              <span>Veuillez sélectionnez un modèle et une langue</span>
            </InfosBar>
          )}

        </Section>

        {/* Customer edit modal */}
        {this.state.showMailEditModal &&
          <ModalEditMail onClose={this.handleCloseMailEditModal} mail={this.state.selectedMail} locale={this.state.selectedLocale} data={this.getMailData(this.state.selectedMail, this.state.selectedLocale.code)} />
        }

      </Container>
    );
  }
}

export default MailScene;
