let Values = {
  CITY_PARENTS_MEET: 'CITY_PARENTS_MEET',
  FIRST_BOSS_NAME: 'FIRST_BOSS_NAME',
  STREET_NAME_GROW_UP: 'STREET_NAME_GROW_UP',
  FIRST_BATHED_BEACH_NAME: 'FIRST_BATHED_BEACH_NAME',
  FIRST_BOUGHT_ALBUM: 'FIRST_BOUGHT_ALBUM',
  FAVORITE_SPORTS_TEAM_NAME: 'FAVORITE_SPORTS_TEAM_NAME'
};

export default Object.seal({
  ...Values,

  /* All values */
  values: Object.keys(Values),

  /* Get name for value. */
  name: (value) => {
    switch (value) {
      case Values.CITY_PARENTS_MEET:
        return `Dans quelle ville vos parents se sont-ils rencontrés ?`;
      case Values.FIRST_BOSS_NAME:
        return `Comment s’appelait votre premier patron ?`;
      case Values.STREET_NAME_GROW_UP:
        return `Quel est le nom de la rue où vous avez grandi ?`;
      case Values.FIRST_BATHED_BEACH_NAME:
        return `Quel est le nom de la première plage où vous vous êtes baigné ?`;
      case Values.FIRST_BOUGHT_ALBUM:
        return `Quel est le premier album que vous avez acheté ?`;
      case Values.FAVORITE_SPORTS_TEAM_NAME:
        return `Quel est le nom de votre équipe de sport préférée ?`;
    }
  }
});
