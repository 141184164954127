import React from 'react'
import PropTypes from 'prop-types'

import { Input as SemanticInput } from 'semantic-ui-react';

class Input extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, { value }) {

    this.setState({
      value: value
    });
  }

  render() {

    let { value, ...rest} = this.props;

    return (
      <SemanticInput {...rest} value={this.state.value} onChange={this.handleChange} />
    );
  }
}

export default Input;
