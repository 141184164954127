import React from 'react'
import PropTypes from 'prop-types'

import { Section, EventFeed } from 'components';
import { Dropdown, Container, Icon } from 'semantic-ui-react';

import { Constants } from 'utils';

//--Constants
const MAX_ACTIVITY = 5;

class PaneActivity extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activityFilter: EventFeed.FILTER_ALL,
    };

    this.handleFilterActivity = this.handleFilterActivity.bind(this);

    this.mapEvent = this.mapEvent.bind(this);
  }

  handleFilterActivity(event, { value }) {

    this.setState({
      activityFilter: value
    });
  }

  mapEvent(event) {

    // eslint-disable-next-line default-case
    switch (event.type) {

      case EventFeed.TYPE_ACTIVATION: {
        return {
          icon: 'flag checkered',
          summary: `La valise vient d'être activée avec le code ${Constants.getActivationCode(event.data.code)}`
        }
      }

      case EventFeed.TYPE_DESACTIVATION: {
        return {
          icon: (
            <Icon.Group>
              <Icon name='flag checkered' />
              <Icon corner name='times' color="red" />
            </Icon.Group>
          ),
          summary: `La valise vient d'être désactivée`
        }
      }

      case EventFeed.TYPE_INSTALLATION: {
        let version = `<Link to="/versions/${event.data.version}">${event.data.version}</Link>`;

        return {
          icon: 'download',
          summary: `La version ${version} vient d'être installée sur la valise`
        }
      }

      case EventFeed.TYPE_BACKUP_IMPORT: {

        let backup = `<Link to="/dosicases/${event.dosicase}/backups/${event.data.backup}">sauvegarde</Link>`;
        let version = `<Link to="/versions/${event.data.version}">${event.data.version}</Link>`;

        return {
          icon: 'cloud upload',
          summary: `Une ${backup} vient d'être éffectuée en version ${version}`
        }
      }

      case EventFeed.TYPE_BACKUP_EXPORT: {

        let backup = `<Link to="/dosicases/${event.dosicase}/backups/${event.data.backup}">${event.data.backup}</Link>`;
        let version = `<Link to="/versions/${event.data.version}">${event.data.version}</Link>`;

        return {
          icon: 'cloud upload',
          summary: `La sauvegarde ${backup} vient d'être importée en version ${version}`
        }
      }

      case EventFeed.TYPE_ASSOCIATION: {

        let customer = `<Link to="/customers/${event.data.customer}">#${event.data.customer}</Link>`;

        return {
          icon: 'linkify',
          summary: `La valise vient d'être associée au client ${customer}`
        }
      }

      case EventFeed.TYPE_DISSOCIATION: {

        let customer = `<Link to="/customers/${event.data.customer}">#${event.data.customer}</Link>`;

        return {
          icon: 'unlink',
          summary: `La valise vient d'être dissociée du client ${customer}`
        }
      }

      case EventFeed.TYPE_SYNCHRONIZATION: {

        let version = `<Link to="/versions/${event.data.version}">${event.data.version}</Link>`;

        // eslint-disable-next-line default-case
        switch (event.data.status) {

          case Constants.STATUS_SYNCHRONIZATION_SUCCESS: {
            return {
              icon: (
                <Icon.Group>
                  <Icon name='sync' />
                  <Icon corner name='checkmark' color="green" />
                </Icon.Group>
              ),
              summary: `La valise vient de se synchroniser avec succès en version ${version}`
            }
          }

          case Constants.STATUS_SYNCHRONIZATION_PARTIAL: {
            return {
              icon: (
                <Icon.Group>
                  <Icon name='sync' />
                  <Icon corner name='checkmark' color="yellow" />
                </Icon.Group>
              ),
              summary: `La valise vient de se synchroniser partiellement en version ${version}`,
              extra: `(${Constants.getPartialSynchronizationCauseMessage(event.data.cause)})`
            }
          }

          case Constants.STATUS_SYNCHRONIZATION_FAILED: {
            return {
              icon: (
                <Icon.Group>
                  <Icon name='sync' />
                  <Icon corner name='dont' color="red" />
                </Icon.Group>
              ),
              summary: `La valise vient d'essayer de se synchroniser en version ${version}`,
              extra: `(${Constants.getErrorMessage(event.data.error)})`
            };
          }
        }
      }
    }
  }

  render() {

    const { dosicase } = this.props;

    return (
      <Container>

        <Section title="Activité récente" collapsible actions={[
          <Dropdown icon="filter" value={this.state.activityFilter} options={EventFeed.FILTERS} onChange={this.handleFilterActivity} direction='left' key={0} />,
        ]}>

          {/* Feed */}
          <EventFeed filter={this.state.activityFilter} mapper={this.mapEvent} route={`/dosicases/${dosicase.id}/activity`} max={MAX_ACTIVITY} />

        </Section>

      </Container>
    )
  }
}

export default PaneActivity;
