import React from 'react'
import PropTypes from 'prop-types'

import { Dimmer, Loader } from 'semantic-ui-react';

class Loading extends React.Component {
  render() {
    return (
      <Dimmer active>
        <Loader>
          {this.props.message}
        </Loader>
      </Dimmer>
    );
  }
}

Loading.propTypes = {
  message: PropTypes.string
};

export default Loading;
