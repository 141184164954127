import React from 'react'

import { withRouter } from "react-router-dom";

import { Breadcrumb as SemanticBreadcrumb } from 'semantic-ui-react';
import { BreadcrumbHelper } from 'utils';

import './style.css';

class Breadcrumb extends React.Component {

  constructor(props) {
    super(props);

    this.handleLevelClick = this.handleLevelClick.bind(this);
  }

  handleLevelClick(level) {

    let levels = BreadcrumbHelper.getLevels();
    levels.splice(level.index + 1, levels.length - level.index);
    this.props.history.push(level.path);
  }

  componentWillMount() {
    BreadcrumbHelper.init(this);
  }

  render() {

    let levels = BreadcrumbHelper.getLevels();

    let elements = [];

    levels.forEach((level, index) => {

      let active = (index === levels.length - 1);

      //Add level
      elements.push(
        <SemanticBreadcrumb.Section active={active} onClick={!active ? () => this.handleLevelClick(level) : undefined} key={index}>
          {level.name}
        </SemanticBreadcrumb.Section>
      );

      //Add divider
      if(!active) {
        elements.push(
          <SemanticBreadcrumb.Divider key={`divider#${index}`}/>
        );
      }
    });

    return (
      <SemanticBreadcrumb id="breadcrumb" size="large">
        {elements}
      </SemanticBreadcrumb>
    );
  }
}

export default withRouter(Breadcrumb);
