import React from 'react'
import PropTypes from 'prop-types'

import { Section, Properties, Empty } from 'components';
import { Button, Breadcrumb, Popup, Icon, Grid } from 'semantic-ui-react';

import { Typeof, Constants } from 'utils';

import { ModalEditDosicaseConfiguration } from 'modals';

import './style.css';

class SectionEquipments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
  }

  toggleEditModal() {

    //Show modal
    this.setState({
      showEditModal: !this.state.showEditModal
    });
  }

  handleCloseEditModal(equipments) {

    if (Typeof.object(equipments)) {

      //Update locally
      this.props.dosicase.equipments = equipments;
    }

    //Hide modal
    this.toggleEditModal();
  }

  render() {

    const dosicase = this.props.dosicase;

    let equipmentProperties = [
      {
        name: 'Format',
        value: Constants.getEquipmentFormat(dosicase.equipments.format)
      },
      {
        name: 'Dosimètres',
        value: (
          <Grid celled="internally" columns={2} className="equipment-line">

            {/* Count */}
            <Grid.Column>
              {dosicase.equipments.dosimeters_count}
            </Grid.Column>

            {/* Firmware */}
            <Grid.Column>

              {Typeof.string(dosicase.equipments.dosimeters_firmware) ? (
                dosicase.equipments.dosimeters_firmware
              ) : (
                <Empty />
              )}

            </Grid.Column>

          </Grid>
        )
      },
      {
        name: 'Sources',
        value: (
          <Grid celled="internally" columns={2} className="equipment-line">

            {/* Count */}
            <Grid.Column>
              {dosicase.equipments.sources_count}
            </Grid.Column>

            {/* Firmware */}
            <Grid.Column>

              {Typeof.string(dosicase.equipments.sources_firmware) ? (
                dosicase.equipments.sources_firmware
              ) : (
                <Empty />
              )}

            </Grid.Column>

          </Grid>
        )
      },
      {
        name: 'Radiamètres',
        value: (
          <Grid celled="internally" columns={2} className="equipment-line">

            {/* Count */}
            <Grid.Column>
              {dosicase.equipments.radiameters_count}
            </Grid.Column>

            {/* Firmware */}
            <Grid.Column>

              {Typeof.string(dosicase.equipments.radiameters_firmware) ? (
                dosicase.equipments.radiameters_firmware
              ) : (
                <Empty />
              )}

            </Grid.Column>

          </Grid>
        )
      }
    ];

    return (
      <Section title={<span><Icon name="microchip" />Équipements</span>} actions={[
        <Button icon="edit" circular color="yellow" onClick={this.toggleEditModal} key={0} />,
      ]} collapsible collapsed>

        <Properties properties={equipmentProperties} />

        {/* Account edit modal */}
        {this.state.showEditModal &&
          <ModalEditDosicaseConfiguration onClose={this.handleCloseEditModal} dosicase={dosicase} />
        }

      </Section>

    );
  }
}

export default SectionEquipments;
