import React from 'react'
import PropTypes from 'prop-types'

import { FormWrapper, Input, Selection } from 'components';

import { Form, Modal, Header, TransitionablePortal } from 'semantic-ui-react'

import { API } from 'services';
import { Constants } from 'utils';

import { SecurityQuestionGroup1, SecurityQuestionGroup2, SecurityQuestionGroup3 } from 'enums';

class ModalEditDosicaseAccount extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      actions: {
        submit: {
          name: "Valider",
          callback: this.handleSubmit
        },
        cancel: {
          name: "Annuler",
          callback: this.props.onClose
        }
      }
    };
  }

  handleSubmit(data, onSucess, onError) {

    API.put(`/dosicases/${this.props.dosicase.id}/account`, data)
      .then((response) => {
        this.props.onClose(response.data);
      })
      .catch((error) => {
        onError(API.violations(error));
      });
  }

  getSecurityQuestions(index) {

    let group = null;

    switch (index) {
      case 1:
        group = SecurityQuestionGroup1;
        break;
      case 2:
        group = SecurityQuestionGroup2;
        break;
      case 3:
        group = SecurityQuestionGroup3;
        break;
    }

    return group.values.map((question) => {
      return {
        key: question,
        value: question,
        text: group.name(question)
      };
    });
  }

  render() {

    let account = this.props.dosicase.account;

    return (
      <TransitionablePortal open transition={{ animation: "fade down", duration: 250 }}>
        <Modal open size="small">
          <Header icon="edit" content="Modification valise – Apple ID" color="yellow" />
          <Modal.Content>

            <FormWrapper actions={this.state.actions} sanitize>

              {/* Birthdate */}
              <Form.Field>
                <label htmlFor="inputAccountBirthdate">Date de naissance</label>
                <Input type="date" id="inputAccountBirthdate" name="birthdate" value={account.birthdate} />
              </Form.Field>

              {/* Firstname */}
              <Form.Field>
                <label htmlFor="inputAccountFirstname">Prénom</label>
                <Input id="inputAccountFirstname" name="firstname" value={account.firstname} />
              </Form.Field>

              {/* Lastname */}
              <Form.Field>
                <label htmlFor="inputAccountLastname">Nom</label>
                <Input id="inputAccountLastname" name="lastname" value={account.lastname} />
              </Form.Field>

              {/* Password */}
              <Form.Field>
                <label htmlFor="inputAccountPassword">Mot de passe</label>
                <Input id="inputAccountPassword" name="password" value={account.password} />
              </Form.Field>

              {/* Security question 1 */}
              <Form.Field>
                <label htmlFor="inputAccountSecurityQuestion1">Question de sécurité n°1</label>

                <Form.Field>
                  <Form.Group className="inline-group">
                    <Selection id="inputAccountSecurityQuestion1" name="security_question_1" value={account.security_question_1} options={this.getSecurityQuestions(1)} placeholder="Question" clearable search={false} />

                    {/* Security question 1 answer */}
                    <Input id="inputAccountSecurityQuestionAnswer1" name="security_question_answer_1" value={account.security_question_answer_1} placeholder="Réponse" />
                  </Form.Group>
                </Form.Field>

              </Form.Field>

              {/* Security question 2 */}
              <Form.Field>
                <label htmlFor="inputAccountSecurityQuestion2">Question de sécurité n°2</label>

                <Form.Field>
                  <Form.Group className="inline-group">
                    <Selection id="inputAccountSecurityQuestion2" name="security_question_2" value={account.security_question_2} options={this.getSecurityQuestions(2)} placeholder="Question" clearable search={false} />

                    {/* Security question 2 answer */}
                    <Input id="inputAccountSecurityQuestionAnswer2" name="security_question_answer_2" value={account.security_question_answer_2} placeholder="Réponse" />
                  </Form.Group>
                </Form.Field>

              </Form.Field>

              {/* Security question 3 */}
              <Form.Field>
                <label htmlFor="inputAccountSecurityQuestion3">Question de sécurité n°3</label>

                <Form.Field>
                  <Form.Group className="inline-group">
                    <Selection id="inputAccountSecurityQuestion3" name="security_question_3" value={account.security_question_3} options={this.getSecurityQuestions(3)} placeholder="Question" clearable search={false} />

                    {/* Security question 3 answer */}
                    <Input id="inputAccountSecurityQuestionAnswer3" name="security_question_answer_3" value={account.security_question_answer_3} placeholder="Réponse" />
                  </Form.Group>
                </Form.Field>

              </Form.Field>

            </FormWrapper>

          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ModalEditDosicaseAccount.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalEditDosicaseAccount;
