let Values = {
  IDEAL_JOB: 'IDEAL_JOB',
  PREFERED_CHILDREN_BOOK: 'PREFERED_CHILDREN_BOOK',
  FIRST_CAR_MODEL: 'FIRST_CAR_MODEL',
  CHILDHOOD_NICKNAME: 'CHILDHOOD_NICKNAME',
  STUDENT_FAVORITE_CHARACTER_OR_ACTOR: 'STUDENT_FAVORITE_CHARACTER_OR_ACTOR',
  STUDENT_FAVORITE_SINGER_OR_BAND: 'STUDENT_FAVORITE_SINGER_OR_BAND'
};

export default Object.seal({
  ...Values,

  /* All values */
  values: Object.keys(Values),

  /* Get name for value. */
  name: (value) => {
    switch (value) {
      case Values.IDEAL_JOB:
        return `Quel serait selon vous le métier idéal ?`;
      case Values.PREFERED_CHILDREN_BOOK:
        return `Quel est le livre pour enfants que vous préférez ?`;
      case Values.FIRST_CAR_MODEL:
        return `Quel était le modèle de votre premier véhicule ?`;
      case Values.CHILDHOOD_NICKNAME:
        return `Quel était votre surnom lorsque vous étiez enfant ?`;
      case Values.STUDENT_FAVORITE_CHARACTER_OR_ACTOR:
        return `Quel était votre personnage ou acteur de cinéma préféré lorsque vous étiez étudiant ?`;
      case Values.STUDENT_FAVORITE_SINGER_OR_BAND:
        return `Quel était votre chanteur ou groupe préféré lorsque vous étiez étudiant ?`;
    }
  }
});
