import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames';

import './style.css';

class InfosBar extends React.Component {

  render() {

    let classNames = classnames('infos-bar', this.props.mode);

    return (
      <div className={classNames}>
        {this.props.children}
      </div>
    );
  }
}

InfosBar.defaultProps = {
  mode: 'column'
};

InfosBar.propTypes = {
  mode: PropTypes.oneOf(['column', 'row']).isRequired
};

export default InfosBar;
