import React from 'react'
import PropTypes from 'prop-types'

import { API } from 'services';

import { AbstractContainer } from 'containers';

import { VersionListScene } from 'scenes';

class VersionListContainer extends AbstractContainer {
  getTasks() {

    return [
      {
        group: 1,
        key: 'versions',
        exec: (done, failure) => {
          API.get('versions')
            .then((response) => {
              done(response.data);
            })
            .catch(failure);
        }
      }
    ]
  }

  getComponent(results) {
    return <VersionListScene versions={results.versions} />
  }
}

export default VersionListContainer;
